import React, { useState } from "react";

import { DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { useAcknowledgeTaskMutation } from "api/taskbox/acknowledgeTask";
import MISDialog from "components/common/MISDialog";
import MISFooterButton from "components/common/MISFooterButton";
import { useNavigate } from "react-router-dom";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { useDeleteNcByIdMutation } from "api/Jira/deleteNcById";
import { toast } from "react-toastify";
import MISTextField from "components/common/MISTextField";
import T from "T";

const NcModal = ({ showDialog, nc, handleShowDialog, handleEmptyTask, refreshTable }) => {
  const navigate = useNavigate();

  const { user } = MISCurrentUser();
  const [comments, setComments] = useState("");

  const [acknowledgeTask] = useAcknowledgeTaskMutation();
  const [deleteNcById] = useDeleteNcByIdMutation();

  const handleDeleteNC = () => {
    const { task, taskId, object } = nc;

    deleteNcById({ ncId: object?.id, userId: user?.user?.member?.id, ncRequestDto: { comments: comments } })
      .unwrap()
      .then(() => {
        refreshTable();

        toast.success(T.NC_DELETED_SUCCESSFULLY);
      })
      .catch(handleError)
      .finally(() => {
        setComments("");
      });
    handleShowDialog();
  };

  const handleIgnore = () => {
    const { task, taskId, object } = nc;

    acknowledgeTask({ taskId })
      .unwrap()
      .then((res) => {
        refreshTable();
      })
      .catch(handleError)
      .finally(() => {
        setComments("");
      });
    handleShowDialog();
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setComments(value);
  };

  return (
    <MISDialog open={showDialog} handleClose={handleShowDialog} disableEscapeKeyDown>
      <DialogTitle>
        <Typography variant="title">{"NC"}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{"Are you sure you want to delete this NC?"}</Typography>

        <Grid container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12} sx={{ pt: 2 }}>
            <MISTextField
              fullWidth
              placeholder={T.WRITE_YOUR_COMMENTS}
              size="small"
              variant="outlined"
              name="comments"
              value={comments}
              required
              onChange={onHandleChange}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          cancelButtonText={T.NO}
          proceedButtonText={T.YES}
          handleSubmit={handleDeleteNC}
          handleClose={handleIgnore}
        />
      </DialogActions>
    </MISDialog>
  );
};

export default NcModal;
