import React, { Children } from "react";
import { Checkbox, FormControlLabel, Grid, Switch, Typography, styled } from "@mui/material";
import T from "T";
import { BACKGROUND, ERROR, NETSMARTZ_THEME_COLOR } from "theme/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { get, noop } from "lodash";
import MISTextField from "components/common/MISTextField";
import MISDatePicker from "components/common/MISDatePicker";
import { MainTitleTypography } from "utils/recommendedMembers";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import MISAutocomplete from "components/common/MISAutocomplete";

const StyledSwitch = styled((props) => <Switch {...props} />)(({ theme, checked, text, transform, sx }) => {
  return {
    padding: 8,
    "& .MuiSwitch-switchBase .MuiSwitch-input": {
      left: "0%",
      width: `${parseInt(sx.width, 0) * 2}%`,
    },
    "& .MuiSwitch-track": {
      borderRadius: 45 / 2,
      "&::before, &::after": {
        position: "absolute",
        top: "51%",
        transform: "translateY(-50%)",
      },
      "&::before": {
        content: checked ? `"${text[0]}"` : '""',
        left: 15,
        color: theme.palette.background.paper,
      },
      "&::after": {
        content: checked ? '""' : `"${text[1]}"`,
        right: 15,
        color: theme.palette.background.paper,
      },
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${NETSMARTZ_THEME_COLOR} !important`,
      opacity: "1 !important",
    },
    "& .MuiSwitch-thumb": {
      color: BACKGROUND.white,
      boxShadow: "none",
      width: 45 / 2,
      height: 45 / 2,
      margin: 2.5,
      transform: checked ? `translateX(${transform}%)` : "translateX(0%)",
    },
  };
});

const ClientDetail = ({
  id = "",
  projectClients = [],
  savedProjectClients = [],
  invalidClientFields,
  clientDetailFields,
  departmentsList = [],
  countriesList = [],
  onHandleReqAddMore = noop,
  removeRow = noop,
  handleClientChange = noop,
  handleGiftSwitch = noop,
  handleClientDateChange = noop,
  handleClientAutoCompleteChange = noop,
  handleSameAsCompanyAddress = noop,
}) => {
  const isInvalid = (index, fieldName) =>
    get(invalidClientFields, `${index}`, [])?.includes(fieldName) &&
    clientDetailFields.find((item) => item.name === fieldName)?.value(index);

  const renderIcons = (index) => (
    <>
      {index === projectClients.length - 1 && (
        <Grid
          container
          md={"auto"}
          xs={"auto"}
          sx={{ ml: "16px", cursor: "pointer" }}
          onClick={() => {
            onHandleReqAddMore();
          }}
        >
          <AddRoundedIcon sx={{ fontSize: 35, color: NETSMARTZ_THEME_COLOR }} />
          <Typography sx={{ fontSize: 16, fontWeight: 650, alignContent: "center", color: NETSMARTZ_THEME_COLOR }}>
            {"Add More SPOC"}
          </Typography>
        </Grid>
      )}
      {((id && index >= savedProjectClients.length && index !== 0) ||
        (!id && index > 0) ||
        (projectClients.length > 1 && index >= 0)) && (
        <Grid
          container
          md={"auto"}
          xs={"auto"}
          sx={{ ml: "16px", cursor: "pointer" }}
          onClick={() => {
            removeRow(index);
          }}
        >
          <RemoveRoundedIcon sx={{ fontSize: 35, color: NETSMARTZ_THEME_COLOR }} />
          <Typography sx={{ fontSize: 16, fontWeight: 650, alignContent: "center", color: NETSMARTZ_THEME_COLOR }}>
            {"Remove SPOC"}
          </Typography>
        </Grid>
      )}
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight={600}>
          {T.CLIENT_DETAILS.toLocaleUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        {Children.toArray(
          projectClients.map((data, index) => {
            const selectedCountry = countriesList.find((item) => item.name === projectClients[index]["clientCountry"]);
            return (
              <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                <Grid item xs={12}>
                  <Grid item sx={{ display: "flex", alignItems: "center", backgroundColor: BACKGROUND.header }}>
                    <Typography fontSize={16} fontWeight={600}>
                      {`${T.SPOC} ${index + 1}`}
                    </Typography>
                    {renderIcons(index)}
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISTextField
                    label={T.NAME}
                    required
                    fullWidth
                    placeholder={T.NAME}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="clientName"
                    value={projectClients[index]["clientName"]}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isInvalid(index, "clientName") && `3px solid ${ERROR.main}`,
                      },
                    }}
                    onChange={(e) => handleClientChange(e, index)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISTextField
                    label={T.EMAIL_ID}
                    required
                    fullWidth
                    placeholder={T.EMAIL_ID}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="clientEmailId"
                    value={projectClients[index]["clientEmailId"]}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isInvalid(index, "clientEmailId") && `3px solid ${ERROR.main}`,
                      },
                    }}
                    onChange={(e) => handleClientChange(e, index)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISDatePicker
                    label={T.BIRTHDAY}
                    placeholder={T.BIRTHDAY}
                    name="clientBirthday"
                    format="DD/MM/YYYY"
                    value={projectClients[index]["clientBirthday"]}
                    handleChange={(newValue, type) => handleClientDateChange(newValue, type, index)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISAutocomplete
                    label={T.DEPARTMENT}
                    required
                    listDetails={departmentsList}
                    value={projectClients[index]["department"]}
                    getByLabelText={(option) => get(option, "departmentName", "")}
                    onChange={(e, newValue) => handleClientAutoCompleteChange("department", newValue, index)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISTextField
                    label={T.DESIGNATION}
                    fullWidth
                    placeholder={T.DESIGNATION}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="clientDesignation"
                    value={projectClients[index]["clientDesignation"]}
                    onChange={(e) => handleClientChange(e, index)}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <MISAutocomplete
                    label={T.COUNTRY}
                    listDetails={countriesList}
                    value={selectedCountry}
                    getByLabelText={(option) => get(option, "name", "")}
                    onChange={(e, newValue) => handleClientAutoCompleteChange("clientCountry", get(newValue, "name", ""), index)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISTextField
                    label={T.PHONE}
                    fullWidth
                    placeholder={T.PHONE}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="clientPhone"
                    value={projectClients[index]["clientPhone"]}
                    onChange={(e) => handleClientChange(e, index)}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isInvalid(index, "clientPhone") && `3px solid ${ERROR.main}`,
                      },
                    }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MISTextField
                    label={T.ADDRESS}
                    fullWidth
                    placeholder={T.ADDRESS}
                    autoComplete="on"
                    size="small"
                    variant="outlined"
                    name="clientAddress"
                    value={projectClients[index]["clientAddress"]}
                    onChange={(e) => handleClientChange(e, index)}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography variant="body" component="legend" color="text.label">
                    {T.SAME_AS_COMPANY}
                  </Typography>
                  <Checkbox
                    name="sameAsCompany"
                    checked={projectClients[index]["sameAsCompany"]}
                    onChange={(e) => handleSameAsCompanyAddress(index, e)}
                  />
                </Grid>
                <Grid container md={"auto"} xs={4}>
                  <Grid md={"auto"} sx={{ mt: 5.75, ml: "16px" }}>
                    <MainTitleTypography sx={{ fontSize: 14, fontWeight: 500 }}>{T.GIFT_ELIGIBITY}</MainTitleTypography>
                  </Grid>
                  <Grid md={"auto"} sx={{ mt: 4 }}>
                    <StyledSwitch
                      sx={{ height: "45px", width: "80px" }}
                      text={["Yes", "No"]}
                      transform={65}
                      checked={projectClients[index]["clientGift"]}
                      onChange={(e) => handleGiftSwitch(index, "clientGift")}
                      disableRipple
                    />
                  </Grid>
                </Grid>
                <Grid container md={"auto"} xs={4}>
                  <Grid item md={"auto"} sx={{ mt: 5.75, ml: "0px" }}>
                    <MainTitleTypography sx={{ fontSize: 14, fontWeight: 500 }}>{T.STATUS}</MainTitleTypography>
                  </Grid>
                  <Grid item md={"auto"} sx={{ mt: 4 }}>
                    <StyledSwitch
                      sx={{ height: "45px", width: "110px" }}
                      text={["Active", "Inactive"]}
                      transform={200}
                      checked={projectClients[index]["status"]}
                      onChange={(e) => handleGiftSwitch(index, "status")}
                      disableRipple
                    />
                  </Grid>
                </Grid>
                <Grid container md={"auto"} xs={4}>
                  <Grid md={"auto"} sx={{ mt: 5.75, ml: "0px" }}>
                    <MainTitleTypography sx={{ fontSize: 14, fontWeight: 500 }}>{"CSM Connect"}</MainTitleTypography>
                  </Grid>
                  <Grid md={"auto"} sx={{ mt: 4 }}>
                    <StyledSwitch
                      sx={{ height: "45px", width: "80px" }}
                      text={["Yes", "No"]}
                      transform={65}
                      checked={projectClients[index]["csmConnect"]}
                      onChange={(e) => handleGiftSwitch(index, "csmConnect")}
                      disableRipple
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          }),
        )}
      </Grid>
    </Grid>
  );
};

export default ClientDetail;
