import T from "T";
import allRoutes from "router/routes";
import {
  canViewRole,
  canEditUser,
  canViewUser,
  canDeleteUser,
  canAddTraining,
  canViewFeedback,
  canViewTechAssignment,
  canViewTechnicalInterview,
  canViewHardware,
} from "utils/permissions";
const {
  PERSONAL_INFO,
  CONTACT_INFO,
  PRIMARY_PROJECT,
  SECONDARY_PROJECT,
  EXPERIENCE,
  TECHNOLOGY,
  CLIENT_PROJECT_MODE,
  AVAILABILITY,
  AVAILABILITY_DATE,
  CLIENT_JIRA,
  INTERNAL_JIRA_EXEMPTION,
  REPORTING_MANAGER,
  ACCOUNT_MANAGER,
  JIRA_NOT_FILLED,
  COMMENTS_MISSING,
  SVN_CHECKIN,
  DELETED,
  LOGGED_HRS,
  FUNCTIONAL_MANAGER,
  TRAINING,
  COMMENTS,
  EMP_MODE,
  CV_UPLOAD,
  CV_DOWNLOAD,
  REMOVE_SHORTLIST,
  EDIT_ALL_PITCHED,
  S_NO,
  TYPE_OF_NC,
  STATUS,
  LEAD_DATE,
  LEAD_INFO,
  RECOMMENDED_BY,
  DAYS_IN_OPERATION,
  DAYS_IN_SALES,
  NO_OF_POSITIONS,
  TYPE,
  TECH_STACK,
  PITCHED_CANDIDATE,
  HIRING_CRITERIA,
  NC_MONTH,
  NC_COUNT,
  DELETED_NC_COUNT,
  DATE_OF_NC,
  DELETE_NC,
  NC_STATUS,
  PROJECT_NAME,
  START_DATE,
  END_DATE,
  COURSE_START_DATE,
  COURSE_END_DATE,
  FEEDBACK,
  PROJECT_MANAGER,
  EDIT_INFO,
  JOB_DESCRIPTION,
  ACTION_BUTTONS,
  BYT_MANAGER,
  CREATED_BY,
  STRENGTHS,
  WEAKNESSES,
  OPPORTUNITIES,
  THREATS,
  DELETE_PROJECT_INFO,
  TECHNOLOGY_NAME,
  DELETE_TECHNOLOGY,
  WORK_LOCATIONS,
  DELETE_LOCATION,
  DATE,
  CREATED_AT,
  EMP_NAME,
  SELECT_ALL,
  EMP_CODE,
  // COURSE_DETAIL
  COURSE_NAME,
  COURSE_DESCRIPTION,
  TRAINING_DURATION,
  IN_HRS,
  SUB_COURSE_COUNT,
  SUB_COURSES,
  ASSESSMENT,
  COURSE_CREATED_BY,
  MEMBER_INFO,
  ASSIGNED_BY,
  COURSE_TYPE,
  RATING,
  CASE_STUDY_DUE_DATE,
  CASE_STUDY_LINK,
  CASE_STUDY_UPLOAD,
  CASE_STUDY_STATUS,
  CASE_STUDY_SUBMISSION_DATE,
  IS_PUBLISHED,
  CASE_STUDY_ACTION,
  CASE_STUDY_PROJECT_START_DATE,
  CASE_STUDY_PROJECT_MANAGER,
  TESTIMONIAL_LINK,
  FEEDBACK_CREATED_BY,
  TECHNICAL_FEEDBACK,
  CLIENT_FEEDBACK,
  COMMUNICATION_SKILLS,
  AVAILABILITY_FEEDBACK,
  TEAM_PLAYER,
  FEEDBACK_TRAINING,
  TAKING_INITIATIVES,
  EDP,
  COMMENTS_FEEDBACK,
  DISCIPLINE,
  FEEDBACK_CREATED_ON,
  PROCESS_COMPLIANCE,
  CLIENT_NAME,
  EXISTING_NEW,
  INTERVIEW_PROCESS,
  INTERVIEW_PLATFORM,
  PLATFORM,
  MANAGER,
  REASONS_FOR_REJECTION,
  CV_UPLOADED,
  DEFAULT_CV,
  DETAILS,
  PENDING_DAYS,
  ASSIGN_DATE,
  ASSIGNED_ON,
  DUE_DATE,
  COMPLETION_DATE,
  EDIT_ROLE,
  REVIEW_DATE,
  INTERVIEW_SCHEDULE,
  SIR,
  REPORTED_ON,
  REPORTED_BY,
  CATEGORY,
  REQUIREMENT_TYPE,
  REGION,
  SHIFT,
  UPDATED_AT,
  ASSIGNMENT_DATE,
  SUBMISSION_DATE,
  TIME_TAKEN,
  TOPIC,
  DURATION,
  EMAIL,
  ATTEMPTED_ON,
  RECORDINGS,
  COMMENT,
  TOOLS_NAME,
  PROFIT_CENTER,
  COST_CENTER,
  COST_PRICE,
  PURCHASE_DATE,
  TENURE,
  USERNAME,
  LICENSE_HOLDER,
  AUTO_RENEWAL,
  LICENCE_PERIOD,
  RENEWAL_DATE,
  REMARKS,
  PREVIOUS_COMPANY,
  NAME,
  REFERRED_BY,
  REFERRAL_NAME,
  PHONE_NUMBER,
  REFERRAL_EMAIL,
  SKILLS,
  DEPARTMENT,
  REFERRAL_DATE,
  COMPANY,
  LAST_REQUEST,
  REFERRAL_COUNT,
  COMPANY_NAME,
  NO_OF_EMPLOYEES,
  LOCATION,
  ACTIVITY_LOG,
  LEVEL,
  TITLE,
  DESCRIPTION,
  UPDATED_BY,
  JOB_TITLE,
  TOPICS,
  RESULT,
  ASSIGN_L_D,
  TRAINING_TYPE,
  ASSIGN_HARDWARE,
  EDIT_HARDWARE,
  ASSIGNED_HARDWARE,
  ASSIGN_REVOKE_HARDWARE,
  TOOL_TYPE,
} = T;

export const COL_KEYS = {
  info: "personalInfo",
  contact: "contactInfo",
  primaryProject: "primaryProject",
  secondaryProject: "secondaryProject",
  experience: "experience",
  workLocation: "workLocation",
  technology: "technology",
  clientProjectMode: "clientProjectMode",
  availability: "availability",
  availabilityDate: "availabilityDate",
  clientJira: "clientJira",
  internalJiraExemption: "internalJiraExemption",
  reportingManager: "reportingManager",
  functionalManager: "functionalManager",
  trainings: "trainings",
  comments: "comments",
  empMode: "empMode",
  cvUpload: "cvUpload",
  cvDownload: "cvDownload",
  cvUploaded: "cvUploaded",
  codeAssessment: "codeAssessment",
  scheduleInterview: "scheduleInterview",
  removeShortlist: "removeShortlist",
  clientName: "clientName",
  existingNew: "existingNew",
  startDate: "startDate",
  endDate: "endDate",
  interviewProcess: "interviewProcess",
  interviewPlatform: "interviewPlatform",
  jdid: "jdid",
  status: "status",
  reasonsForRejection: "reasonsForRejection",
  editInterviewTracker: "editInterviewTracker",
  cancelInterview: "cancelInterview",
  action: "action",
  defaultCv: "defaultCv",
  interviewSchedule: "interviewSchedule",
  serialnumber: "serialnumber",
  bytManager: "bytManager",
  assignedHardware: "assignedHardware",
};

export const TABLE_COLUMNS = [
  {
    position: 0,
    label: REMOVE_SHORTLIST,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.removeShortlist,
  },
  {
    position: 1,
    label: S_NO,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.serialnumber,
  },
  {
    position: 2,
    label: PERSONAL_INFO,
    checked: true,
    locked: true,
    columnKey: COL_KEYS.info,
  },
  {
    position: 3,
    label: CONTACT_INFO,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.contact,
  },
  {
    position: 4,
    label: PRIMARY_PROJECT,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.primaryProject,
  },
  {
    position: 5,
    label: SECONDARY_PROJECT,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.secondaryProject,
  },
  {
    position: 6,
    label: EXPERIENCE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.experience,
  },
  {
    position: 7,
    label: TECHNOLOGY,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.technology,
  },
  {
    position: 8,
    label: CLIENT_PROJECT_MODE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.clientProjectMode,
  },
  {
    position: 9,
    label: AVAILABILITY,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.availability,
  },
  {
    position: 10,
    label: AVAILABILITY_DATE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.availabilityDate,
  },
  {
    position: 11,
    label: CLIENT_JIRA,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.clientJira,
  },
  {
    position: 12,
    label: INTERNAL_JIRA_EXEMPTION,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.internalJiraExemption,
  },
  {
    position: 13,
    label: REPORTING_MANAGER,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.reportingManager,
  },
  {
    position: 14,
    label: FUNCTIONAL_MANAGER,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.functionalManager,
  },
  {
    position: 15,
    label: BYT_MANAGER,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.bytManager,
  },
  {
    position: 16,
    label: `${TRAINING}s`,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.trainings,
  },
  {
    position: 17,
    label: COMMENTS,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.comments,
  },
  {
    position: 18,
    label: ASSIGNED_HARDWARE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.assignedHardware,
  },
  {
    position: 19,
    label: EMP_MODE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.empMode,
  },
  {
    position: 20,
    label: DEFAULT_CV,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.defaultCv,
  },
  {
    position: 21,
    label: CV_UPLOADED,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.cvUploaded,
  },
  {
    position: 22,
    label: CV_UPLOAD,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.cvUpload,
  },
  {
    position: 23,
    label: INTERVIEW_SCHEDULE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.interviewSchedule,
  },
  {
    position: 24,
    label: JOB_DESCRIPTION,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.jdid,
  },
  {
    position: 25,
    label: CLIENT_NAME,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.clientName,
  },
  {
    position: 26,
    label: EXISTING_NEW,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.existingNew,
  },
  {
    position: 27,
    label: START_DATE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.startDate,
  },
  {
    position: 28,
    label: END_DATE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.endDate,
  },
  {
    position: 29,
    label: INTERVIEW_PROCESS,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.interviewProcess,
  },
  {
    position: 30,
    label: INTERVIEW_PLATFORM,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.interviewPlatform,
  },
  {
    position: 31,
    label: STATUS,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.status,
  },
  {
    position: 32,
    label: REASONS_FOR_REJECTION,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.reasonsForRejection,
  },
  {
    position: 33,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.codeAssessment,
  },
  {
    position: 34,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.scheduleInterview,
  },
  {
    position: 35,
    label: CV_DOWNLOAD,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.cvDownload,
  },
  {
    position: 36,
    label: EDIT_ALL_PITCHED,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.editInterviewTracker,
  },
  {
    position: 37,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.cancelInterview,
  },
  {
    position: 38,
    label: ACTION_BUTTONS,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.action,
  },
];

export const MEMBER_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.contact ||
    column.columnKey === COL_KEYS.primaryProject ||
    column.columnKey === COL_KEYS.secondaryProject ||
    column.columnKey === COL_KEYS.experience ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.clientProjectMode ||
    column.columnKey === COL_KEYS.availability ||
    column.columnKey === COL_KEYS.availabilityDate ||
    column.columnKey === COL_KEYS.clientJira ||
    column.columnKey === COL_KEYS.internalJiraExemption ||
    column.columnKey === COL_KEYS.reportingManager ||
    column.columnKey === COL_KEYS.functionalManager ||
    column.columnKey === COL_KEYS.trainings ||
    column.columnKey === COL_KEYS.comments ||
    column.columnKey === COL_KEYS.assignedHardware ||
    column.columnKey === COL_KEYS.empMode,
);

export const INSIDE_SALES_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.serialnumber ||
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.experience ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.reportingManager,
);

export const RECOMMENDED_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.contact ||
    column.columnKey === COL_KEYS.primaryProject ||
    column.columnKey === COL_KEYS.secondaryProject ||
    column.columnKey === COL_KEYS.experience ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.reportingManager,
);
export const SHORTLISTED_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.contact ||
    column.columnKey === COL_KEYS.primaryProject ||
    column.columnKey === COL_KEYS.secondaryProject ||
    column.columnKey === COL_KEYS.experience ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.reportingManager ||
    column.columnKey === COL_KEYS.cvUploaded ||
    column.columnKey === COL_KEYS.defaultCv ||
    column.columnKey === COL_KEYS.removeShortlist ||
    column.columnKey === COL_KEYS.cvUpload,
);
export const PITCHED_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.contact ||
    column.columnKey === COL_KEYS.primaryProject ||
    column.columnKey === COL_KEYS.secondaryProject ||
    column.columnKey === COL_KEYS.experience ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.reportingManager ||
    column.columnKey === COL_KEYS.cvDownload ||
    column.columnKey === COL_KEYS.action ||
    column.columnKey === COL_KEYS.interviewSchedule,
);
export const INTERVIEW_TRACKER = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.serialnumber ||
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.clientName ||
    column.columnKey === COL_KEYS.reportingManager ||
    column.columnKey === COL_KEYS.bytManager ||
    column.columnKey === COL_KEYS.jdid ||
    column.columnKey === COL_KEYS.empMode ||
    column.columnKey === COL_KEYS.existingNew ||
    column.columnKey === COL_KEYS.startDate ||
    column.columnKey === COL_KEYS.endDate ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.interviewPlatform ||
    column.columnKey === COL_KEYS.status ||
    column.columnKey === COL_KEYS.reasonsForRejection ||
    column.columnKey === COL_KEYS.cvDownload ||
    column.columnKey === COL_KEYS.editInterviewTracker,
);
export const MEMBER_GROUPS_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.contact ||
    column.columnKey === COL_KEYS.primaryProject ||
    column.columnKey === COL_KEYS.secondaryProject ||
    column.columnKey === COL_KEYS.experience ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.reportingManager,
);
const {
  VIEW,
  EDIT,
  DEACTIVATE,
  SWOT_ANALYSIS,
  ASSIGN_ROLE,
  ASSIGN_MOCK_INTERVIEW,
  // ASSIGN_COURSE,
  REMOVE_ROLE,
  REVOKE,
  DELETE,
  TECH_ASSIGNMENT,
  TECHNICAL_INTERVIEW,
  APPRAISAL_LEARNING_PATH,
  NEW_JOINER_LEARNING_PATH,
} = T;

const { app } = allRoutes;
const { viewMembers, editMembers, feedback, swotAnalysis, specialIncidentRegister, memberAssignments, lndpage } = app;

export const getAllTableMenus = () => {
  return [
    {
      title: VIEW,
      route: viewMembers,
      permission: canViewUser(),
    },
    {
      title: EDIT,
      route: editMembers,
      permission: canEditUser(),
    },
    {
      title: FEEDBACK,
      route: feedback,
      permission: canViewFeedback(),
    },
    {
      title: SWOT_ANALYSIS,
      route: swotAnalysis,
      permission: canViewFeedback(),
    },
    {
      title: SIR,
      route: specialIncidentRegister,
      permission: canViewFeedback(),
    },
    {
      title: DEACTIVATE,
      permission: canEditUser(),
    },
    {
      title: REVOKE,
      permission: canEditUser(),
    },
    {
      title: ASSIGN_ROLE,
      permission: canViewRole(),
    },
    // {
    //   title: ASSIGN_COURSE,
    //   permission: canAddTraining(),
    // },
    {
      title: APPRAISAL_LEARNING_PATH,
      route: lndpage,
      permission: canAddTraining(),
    },
    {
      title: NEW_JOINER_LEARNING_PATH,
      route: lndpage,
      permission: canAddTraining(),
    },
    ,
    {
      title: TECH_ASSIGNMENT,
      permission: canViewTechAssignment(),
    },
    {
      title: ASSIGN_MOCK_INTERVIEW,
      // route: swotAnalysis,
      permission: true,
    },
    {
      title: TECHNICAL_INTERVIEW,
      permission: canViewTechnicalInterview(),
    },
    {
      title: EDIT_ROLE,
      permission: canViewRole(),
    },
    {
      title: DELETE,
      permission: canDeleteUser(),
    },
    {
      title: ASSIGN_HARDWARE,
      permission: canViewHardware(),
    },
    {
      title: ASSIGN_REVOKE_HARDWARE,
      permission: canViewHardware(),
    },
  ];
};

export const NC_TABLE_HEADER = [S_NO, NC_MONTH, TYPE_OF_NC, NC_COUNT, DELETED_NC_COUNT];
export const HISTORY_TABLE_HEADER = [S_NO, PROJECT_NAME, START_DATE, END_DATE, PROJECT_MANAGER, FUNCTIONAL_MANAGER, FEEDBACK];
export const INTERVIEW_TABLE_HEADER = [S_NO, CLIENT_NAME, START_DATE, END_DATE, PLATFORM, STATUS, REASONS_FOR_REJECTION, MANAGER];
export const MOCK_INTERVEIW_HEADER = [S_NO, TITLE, DESCRIPTION, LEVEL];
export const SIR_HEADER = [S_NO, TITLE, TYPE, DESCRIPTION, CREATED_BY, CREATED_AT];

export const PROJECTS_TABLE_HEADER = [
  { label: S_NO },
  { label: PROJECT_NAME },
  { label: START_DATE },
  { label: END_DATE },
  { label: REPORTING_MANAGER },
  { label: FUNCTIONAL_MANAGER },
  { label: EDIT_INFO, align: "center" },
  { label: DELETE_PROJECT_INFO, align: "center" },
];
export const ACCOUNTS_TABLE_HEADER = [
  { label: S_NO },
  { label: EMP_NAME },
  { label: PROJECT_MANAGER },
  { label: ACCOUNT_MANAGER },
  { label: LOGGED_HRS },
];
export const PRE_SALES_TABLE_HEADER = [
  REGION,
  SHIFT,
  DAYS_IN_OPERATION,
  DAYS_IN_SALES,
  JOB_TITLE,
  NO_OF_POSITIONS,
  UPDATED_AT,
  STATUS,
  TYPE,
  DETAILS,
  EDIT,
];

export const MANAGER_ASSIGNMENTS_TABLE_HEADER = [
  { label: S_NO },
  { label: ASSIGNMENT_DATE },
  { label: SUBMISSION_DATE },
  { label: TIME_TAKEN },
  { label: TOPIC },
  { label: TECHNOLOGY },
  { label: `${DURATION}` },
  { label: STATUS },
  { label: ACTION_BUTTONS },
  // { label: DAYS_IN_OPERATION, align: "center" },
];
export const MEMBER_ASSIGNMENTS_TABLE_HEADER = [
  { label: S_NO },
  { label: ASSIGNMENT_DATE },
  { label: SUBMISSION_DATE },
  { label: TIME_TAKEN },
  { label: TOPIC },
  { label: TECHNOLOGY },
  { label: STATUS },
  { label: ACTION_BUTTONS },
  // { label: DAYS_IN_OPERATION, align: "center" },
];

export const PRE_SALES_REPORT_CARD_HEADER = [
  { label: CLIENT_NAME },
  { label: REQUIREMENT_TYPE },
  // { label: DAYS_IN_OPERATION },
  // { label: DAYS_IN_SALES },
  { label: TECH_STACK },
  { label: EMP_MODE },
  { label: PITCHED_CANDIDATE },
  // { label: HIRING_CRITERIA },
  { label: STATUS },
  { label: REASONS_FOR_REJECTION },
  { label: FEEDBACK },
];

export const TECHNOLOGY_TABLE_HEADER = [
  { label: S_NO },
  { label: TECHNOLOGY_NAME },
  { label: EDIT_INFO, align: "center" },
  { label: DELETE_TECHNOLOGY, align: "center" },
];

export const WORK_LOCATION_TABLE_HEADER = [
  { label: S_NO },
  { label: WORK_LOCATIONS },
  { label: EDIT_INFO, align: "center" },
  { label: DELETE_LOCATION, align: "center" },
];

export const USER_NC_BY_DATE_HEADER = [
  { label: S_NO },
  { label: PROJECT_NAME },
  { label: PROJECT_MANAGER },
  { label: FUNCTIONAL_MANAGER },
  { label: LOGGED_HRS, align: "center" },
];

export const JIRA_NOT_FILLED_USER_ACTIVE_NC_HEADER = [
  S_NO,
  DATE_OF_NC,
  PROJECT_NAME,
  PROJECT_MANAGER,
  REPORTING_MANAGER,
  ACCOUNT_MANAGER,
  TYPE_OF_NC,
  STATUS,
  LOGGED_HRS,
];

export const COMMENTS_MISSING_USER_ACTIVE_NC_HEADER = [
  S_NO,
  DATE_OF_NC,
  PROJECT_NAME,
  PROJECT_MANAGER,
  REPORTING_MANAGER,
  ACCOUNT_MANAGER,
  STATUS,
  TYPE_OF_NC,
];
export const NC_STATUS_LIST = [
  { id: 0, label: T.ACTIVE },
  { id: 1, label: T.REQUEST_TO_DELETE },
  { id: 2, label: T.DELETE },
];
export const USER_DELETED_NC_HEADER = [
  S_NO,
  DATE_OF_NC,
  PROJECT_NAME,
  PROJECT_MANAGER,
  REPORTING_MANAGER,
  ACCOUNT_MANAGER,
  TYPE_OF_NC,
  STATUS,
  COMMENTS,
];
export const TIME_SHEET_TABLE_HEADER_PM = [
  { label: DATE },
  { label: EMP_NAME },
  { label: TYPE_OF_NC },
  { label: NC_STATUS },
  { label: COMMENTS },
  { label: DELETE_NC, align: "center" },
];

export const TIME_SHEET_TABLE_HEADER_FM = [
  { label: DATE },
  { label: EMP_NAME },
  { label: TYPE_OF_NC },
  { label: REPORTING_MANAGER },
  { label: NC_STATUS },
  { label: COMMENTS },
  { label: DELETE_NC, align: "center" },
];

export const TIME_SHEET_TABLE_HEADER_PMO = [
  { label: DATE },
  { label: EMP_NAME },
  { label: TYPE_OF_NC },
  { label: REPORTING_MANAGER },
  { label: NC_STATUS },
  { label: COMMENTS },
  { label: DELETE_NC, align: "center" },
];

export const TIME_SHEET_TABLE_HEADER = [
  { label: DATE },
  { label: EMP_NAME },
  { label: TYPE_OF_NC },
  { label: REPORTING_MANAGER },
  { label: NC_STATUS },
  { label: COMMENTS },
  { label: DELETE_NC, align: "center" },
];
export const CONSOLIDATED_NC_TABLE_HEADER = [
  { label: EMP_NAME },
  { label: EMP_CODE },
  { label: REPORTING_MANAGER },
  { label: JIRA_NOT_FILLED, align: "center" },
  { label: COMMENTS_MISSING, align: "center" },
  { label: SVN_CHECKIN, align: "center" },
  { label: DELETED, align: "center" },
];
export const DELETED_NC_TABLE_HEADER = [
  { label: SELECT_ALL },
  { label: DATE },
  { label: EMP_NAME },
  { label: REPORTING_MANAGER },
  { label: FUNCTIONAL_MANAGER },
  { label: TYPE_OF_NC },
  { label: NC_STATUS },
  { label: COMMENTS },
  { label: DELETE_NC, align: "center" },
];

export const COURSE_TABLE_HEADER = [
  { label: COURSE_NAME },
  { label: COURSE_TYPE },
  { label: TRAINING_DURATION },
  { label: SUB_COURSES },
  { label: SUB_COURSE_COUNT },
  { label: COURSE_CREATED_BY },
  { label: EDIT },
];
export const ASSIGN_TABLE_HEADER = [
  { label: MEMBER_INFO },
  { label: TRAINING_TYPE },
  { label: COURSE_NAME },
  { label: SUB_COURSES },
  { label: TRAINING_DURATION },
  { label: ASSIGNED_BY },
  { label: ASSIGN_DATE },
  { label: COMPLETION_DATE },
  { label: COURSE_START_DATE },
  { label: COURSE_END_DATE },
  { label: REVIEW_DATE },
  { label: PENDING_DAYS },
  { label: RATING },
  { label: ACTION_BUTTONS },
];

export const ASSIGNED_COURSE_TABLE_HEADER = [
  { label: COURSE_NAME },
  { label: TRAINING_TYPE },
  { label: COURSE_TYPE },
  { label: SUB_COURSES },
  { label: `${TRAINING_DURATION}` },
  { label: ASSIGNED_ON },
  { label: DUE_DATE },
  { label: START_DATE },
  { label: END_DATE },
  { label: REVIEW_DATE },
  { label: PENDING_DAYS },
  { label: ACTION_BUTTONS },
];

// Testimonial Table
export const TESTIMONIAL_TABLE_HEADER = [
  { label: S_NO },
  { label: PROJECT_NAME },
  { label: "" },
  { label: CASE_STUDY_PROJECT_MANAGER },
  { label: CASE_STUDY_PROJECT_START_DATE },
  { label: TESTIMONIAL_LINK, align: "center" },
  { label: CASE_STUDY_STATUS, align: "center" },
  { label: COMMENTS, align: "center" },
  { label: COMMENTS, align: "center" },
  { label: CASE_STUDY_SUBMISSION_DATE, align: "center" },
  // { label: CASE_STUDY_ACTION, align: "center" },
];

export const MEMBER_FEEDBACK_TABLE = [
  { label: FEEDBACK_CREATED_BY },
  { label: TECHNICAL_FEEDBACK },
  { label: CLIENT_FEEDBACK },
  { label: COMMUNICATION_SKILLS },
  { label: AVAILABILITY_FEEDBACK },
  { label: TEAM_PLAYER },
  { label: FEEDBACK_TRAINING },
  { label: TAKING_INITIATIVES },
  { label: EDP },
  { label: PROCESS_COMPLIANCE },
  { label: DISCIPLINE },
  { label: COMMENTS_FEEDBACK },
  { label: FEEDBACK_CREATED_ON },
];
export const MEMBER_SWOT_TABLE = [
  { label: S_NO },
  { label: CREATED_BY },
  { label: PROJECT_NAME },
  { label: CREATED_AT },
  { label: STRENGTHS },
  { label: WEAKNESSES },
  { label: OPPORTUNITIES },
  { label: THREATS },
  { label: COMMENTS },
  { label: ACTION_BUTTONS },
];

export const TRAINING_TABLE_HEADER = [
  { label: COURSE_NAME },
  { label: COURSE_DESCRIPTION },
  { label: TRAINING_DURATION },
  { label: SUB_COURSES },
  { label: SUB_COURSE_COUNT },
  { label: ASSESSMENT },
  { label: COURSE_CREATED_BY },
  { label: EDIT },
];
export const SIR_TABLE_HEADER = [
  { label: "" },
  { label: S_NO },
  { label: REPORTED_BY },
  { label: REPORTED_ON },
  { label: CATEGORY },
  { label: COMMENTS },
  { label: ACTION_BUTTONS },
];
export const MOCK_ASSESSMENT_TABLE_HEADER = [
  { label: S_NO },
  { label: EMP_NAME },
  { label: EMAIL },
  { label: ATTEMPTED_ON },
  // { label: RECORDINGS },
  { label: COMMENT },
  { label: "" },
  { label: ACTION_BUTTONS },
];

export const TOOLS_LICENCE_TABLE_HEADER = [
  { label: TOOLS_NAME },
  { label: DEPARTMENT },
  { label: COST_CENTER },
  { label: COST_PRICE },
  { label: LICENSE_HOLDER },
  { label: AUTO_RENEWAL },
  { label: TENURE },
  { label: TOOL_TYPE },
  { label: RENEWAL_DATE },
  { label: CREATED_AT },
  { label: REMARKS },
  { label: CREATED_BY },
  { label: ACTION_BUTTONS },
];
export const REFERRALS_TABLE_HEADER = [
  { label: S_NO },
  { label: EMP_NAME },
  { label: EMAIL },
  { label: REFERRAL_COUNT },
  { label: EXPERIENCE },
  { label: PREVIOUS_COMPANY },
  // { label: LAST_REQUEST },
  { label: "" },
];
export const REFERRALS_LIST_TABLE_HEADER = [
  { label: S_NO },
  { label: NAME },
  { label: RECOMMENDED_BY },
  { label: CREATED_BY },
  { label: TECH_STACK },
  { label: EXPERIENCE },
  { label: PREVIOUS_COMPANY },
  { label: "" },
];

export const MY_REFERRAL_TABLE_HEADER = [
  { label: REFERRAL_NAME },
  { label: REFERRAL_EMAIL },
  { label: PHONE_NUMBER },
  { label: COMPANY },
  { label: DEPARTMENT },
  { label: SKILLS },
  { label: EXPERIENCE },
  { label: REFERRED_BY },
  { label: COMMENT },
  { label: REFERRAL_DATE },
];
export const COMPANIES_TABLE_HEADER = [
  { label: S_NO },
  { label: COMPANY_NAME },
  { label: TECH_STACK },
  { label: NO_OF_EMPLOYEES },
  {
    label: LOCATION,
  },
  {
    label: CREATED_BY,
  },
  {
    label: ACTIVITY_LOG,
  },
  { label: EDIT },
];

export const TECHNICAL_INTERVIEW_HEADER = [
  { label: S_NO },
  { label: TECH_STACK },
  { label: EXPERIENCE },
  { label: CREATED_AT },
  { label: TOPICS },
  { label: STATUS },
  { label: RESULT },
  { label: ACTION_BUTTONS },
];
