import { Children, memo, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { get } from "utils/lodash";
import { TableRow, TableCell, Typography, TableBody, styled, Tooltip } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import MISButton from "components/common/MISButton";
import { NotificationsOutlined } from "@mui/icons-material";
import FeedbackForm from "components/Training/Feedback/Form";
import T from "T";
import ReviewModal from "components/Training/ReviewModal/ReviewModal";
import AcknowledgementModal from "./Acknowlegement/AcknowledgementModal";
import NcModal from "./ NC/NcModal";
import GiftTableModal from "components/BusinessCentral/Gift/GiftTableModal";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "12px 8px 12px 24px",
  fontSize: 13.2,
  maxWidth: 130,
  overflowWrap: "break-word",
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const TaskboxTablebody = ({ allTableRows = [], handleAct = noop, setCurrentFeedBack = noop, refreshTable = noop }) => {
  // const { user } = MISCurrentUser();

  const navigate = useNavigate();

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    showFeedback: false,
    showReviewModal: false,
    showNc: false,
    showGiftModal: false,
    showAcknowledgement: false,
    currentTask: null,
  });
  const { showFeedback, showReviewModal,  showNc, showGiftModal, showAcknowledgement, currentTask } = localState;
  const handleShowFeedback = () => {
    setLocalState({ showFeedback: !showFeedback });
  };
  const handleShowReviewModal = () => {
    setLocalState({ showReviewModal: !showReviewModal });
  };
  const handleShowAcknowledgment = () => {
    setLocalState({ showAcknowledgement: !showAcknowledgement });
  };
  const handleShowNc = () => {
    setLocalState({ showNc: !showNc });
  };
  const handleCurrentTask = (task, taskId, type, object) => {
    if (type?.toLowerCase() === "Acknowledgement".toLowerCase() || type?.toLowerCase() === "User NC".toLowerCase())
      setLocalState({ currentTask: { task: task, taskId: taskId, object: object } });
    else setLocalState({ currentTask: object });
  };
  const handleEmptyTask = () => {
    setLocalState({ currentTask: null });
  };

  const handleNavigateToTraining = (currentTask) => {
    navigate(`/app/training/${currentTask?.id}/traineeCourse`, {
      state: currentTask,
    });
  };

  const handleCurrentModal = (type, currentTask) => {
    switch (type?.toLowerCase()) {
      case "feedback form".toLowerCase():
        handleShowFeedback();
        break;
      case "Pending Training reviews".toLowerCase():
        handleShowReviewModal();
        break;
      case "Trainings pending to be completed".toLowerCase():
        handleNavigateToTraining(currentTask);
        break;
      case "Acknowledgement".toLowerCase():
        handleShowAcknowledgment();
      case "User NC".toLowerCase():
        handleShowNc();
      case "spoc gift":
        handleShowGiftModal();
      case "Acknowledgement":
        handleShowAcknowledgment();
      default:
        break;
    }
  };

  const isOverdue = (dueDate) => {
    if (!dueDate || dueDate === "-") return false;

    // Split the due date string (DD-MM-YYYY)
    const [day, month, year] = dueDate.split("-").map(Number);

    // Create date objects for comparison
    const dueDateObj = new Date(year, month - 1, day); // month is 0-based
    const currentDate = new Date();

    // Reset time portion of current date for accurate date comparison
    currentDate.setHours(0, 0, 0, 0);

    return dueDateObj < currentDate;
  };

  return (
    <>
      <TableBody>
        {Children.toArray(
          allTableRows.map((record, index) => {
            const serialNumber = index + 1;
            const name = get(record, "userName", "-");
            const type = get(record, "taskType", "-");
            const task = get(record, "task", "-");
            const triggerDate = get(record, "triggerDate", "-");
            const dueDate = get(record, "dueDate", "-");
            const courseName = get(record, "taskTitle", "-");
            return (
              <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
                <StyledTableCell
                  sx={{
                    backgroundColor: "white",
                    pl: 4,
                    backgroundColor: "white",
                    borderLeft: isOverdue(dueDate) && `5px solid ${ERROR.main}`,
                  }}
                >
                  <Typography>{serialNumber}</Typography>
                </StyledTableCell>

                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={name} placement="top">
                    <Typography>{name}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={courseName} placement="top">
                    <Typography>{courseName}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={task} placement="top">
                    <Typography>{task}</Typography>
                  </Tooltip>
                </StyledTableCell>

                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={triggerDate} placement="top">
                    <Typography>{triggerDate}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <Tooltip title={dueDate} placement="top">
                    <Typography>{dueDate}</Typography>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell sx={{ padding: "0px 8px 0px 25px" }}>
                  <MISButton
                    variant={"contained"}
                    startIcon={<NotificationsOutlined sx={{ width: 19 }} />}
                    onClick={() => {
                      const currentTask = get(record, "object", null);
                      const taskId = get(record, "taskId", null);

                      handleCurrentTask(task, taskId, type, currentTask);
                      handleCurrentModal(type, currentTask);
                    }}
                  >
                    {T.ACT}
                  </MISButton>
                </StyledTableCell>
              </StyledTableRow>
            );
          }),
        )}
      </TableBody>
      <FeedbackForm
        showDialog={showFeedback}
        handleShowDialog={handleShowFeedback}
        handleEmptyTask={handleEmptyTask}
        feedback={currentTask}
        refreshTable={refreshTable}
      />
      <ReviewModal
        openReview={showReviewModal}
        assignData={currentTask}
        onHandleClose={handleShowReviewModal}
        handleEmptyTask={handleEmptyTask}
        refreshTable={refreshTable}
      />
      <AcknowledgementModal
        showDialog={showAcknowledgement}
        acknowledgement={currentTask}
        handleShowDialog={handleShowAcknowledgment}
        handleEmptyTask={handleEmptyTask}
        refreshTable={refreshTable}
      />
      <NcModal
        handleEmptyTask={handleEmptyTask}
        handleShowDialog={handleShowNc}
        nc={currentTask}
        refreshTable={refreshTable}
        showDialog={showNc}
      />

      {/* <GiftTableModal
        data={currentTask}
        handleEmptyTask={handleEmptyTask}
        handleShowDialog={handleShowGiftModal}
        showDialog={showGiftModal}
        refreshTable={refreshTable}
      /> */}
      <AcknowledgementModal
        showDialog={showAcknowledgement}
        acknowledgement={currentTask}
        handleShowDialog={handleShowAcknowledgment}
        handleEmptyTask={handleEmptyTask}
        refreshTable={refreshTable}
      />
    </>
  );
};

export default memo(TaskboxTablebody);
