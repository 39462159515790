import PropTypes, { bool } from "prop-types";
import { TableCell, TableBody, styled, TableRow, Checkbox, Tooltip, IconButton, Grid, Box } from "@mui/material";
import memo from "utils/react";
import { noop } from "lodash";
import { get } from "utils/lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { SubTitleTypography, TitleTypography } from "utils/members";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import { GIFT_TYPE, OCCASION_LIST } from "../businessCentralModel";
import { MISCurrentUser } from "utils/validations";
import RedeemIcon from "@mui/icons-material/Redeem";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PresentGiftModal from "./PresentGiftModal";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import MISTextField from "components/common/MISTextField";
import GiftHistory from "./GiftHistory";
import { capitalizeWords } from "utils/commonFunction";
import ConfirmReject from "../ConfirmReject";
import { useReducer } from "react";

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const GiftTableBody = ({
  allTableRows = [],
  gifts = [],
  selectedGift = [],
  handleSelectGifts = noop,
  onHandleTableChange = noop,
  onHandleTableAutoComplete = noop,
  isPresentGiftOpen = false,
  isGiftHistoryOpen = false,
  spocName = "",
  spocId = "",
  rejectedGiftId = "",
  isRejectedOpen = false,
  handleGiftModalOpen = noop,
  handleGiftModalClose = noop,
  handleGiftHistoryOpen = noop,
  handleGiftHistoryClose = noop,
  handleChangeStatus = noop,
  handleOpenReject = noop,
  handleCloseReject = noop,
  refreshData = noop,
}) => {
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    comment: "",
  });
  const { comment } = localState;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => {
    const nthChildIndex = userRole === T.COO ? 5 : 4;
    return {
      borderTop: "inherit",
      overflow: "hidden",
      padding: "8px 0px 8px 16px",
      "&:last-child": {
        width: "150px",
        minWidth: "150px",
      },
      ...theme.typography.subtitle1,
      [`&:nth-of-type(${nthChildIndex})`]: {
        width: "275px",
        minWidth: "275px",
      },
    };
  });
  const getColorCode = (status) => {
    if ([1, 2, 3].includes(status)) return "#d3a82c";
    else if (status === 4) return "#40c55e";
    else if (status === 5) return "#d44c4b";
    else return undefined;
  };
  return (
    <>
      <TableBody>
        {allTableRows.map((record, index) => {
          const giftId = get(record, "id", "");
          const giftStatus = get(record, "giftStatus", "");
          const spocId = get(record, "spoc.id", "");
          const disabledCondition = () => (userRole === T.COO ? [4, 5].includes(giftStatus) : [2, 3, 4, 5].includes(giftStatus));
          const customCheck = ![T.FESTIVAL, T.BIRTHDAY, null].includes(gifts.find((item) => item.id === giftId)?.occasion);
          const selectedOccasion = OCCASION_LIST.find((item) => item === gifts?.find((item) => item.id === giftId)?.occasion);
          const commentBE = get(record, "comment", null);
          const giftIndex = gifts.findIndex((item) => item.id === giftId);
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell
                sx={{
                  borderLeft: `5px solid ${getColorCode(giftStatus)}`,
                  borderRadius: "5px 0px 0px 5px",
                }}
              >
                <Checkbox
                  checked={selectedGift.includes(giftId)}
                  disabled={disabledCondition()}
                  onChange={(e) => handleSelectGifts(e, giftId)}
                  name={`${giftId}-checkbox`}
                  id={`${giftId}-checkbox`}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Tooltip placement="top" title={commentBE}>
                  <TitleTypography sx={{ color: commentBE ? "red" : NETSMARTZ_THEME_COLOR }}>
                    {capitalizeWords(get(record, "spoc.name", ""))}
                  </TitleTypography>
                </Tooltip>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.PROJECT}: </SubTitleTypography>
                  <TitleTypography ml={1}>{get(record, "projectId.name", "")}</TitleTypography>
                </Box>
                <Box display="flex" whiteSpace="nowrap">
                  <SubTitleTypography>{T.VICE_PRESIDENT}: </SubTitleTypography>
                  <TitleTypography ml={1}>{get(record, "projectId.projectFunctionalHead.name", "")}</TitleTypography>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <TitleTypography>{get(record, "recommendation", "None")}</TitleTypography>
              </StyledTableCell>
              {userRole === T.COO && (
                <StyledTableCell>
                  <TitleTypography>{get(record, "proposedGift", "None")}</TitleTypography>
                </StyledTableCell>
              )}
              <StyledTableCell>
                <Grid container sx={{ m: 0, p: 0 }} justifyContent={"space-between"}>
                  <Grid md={customCheck ? 5.5 : 12}>
                    <MISAutocomplete
                      id={`${giftId}-occasion`}
                      key={`${giftId}-occasion`}
                      listDetails={OCCASION_LIST}
                      disabled={disabledCondition()}
                      getByLabelText={(option) => option}
                      value={selectedOccasion || null}
                      onHandleChange={(e, newValue) => onHandleTableAutoComplete(newValue, giftId, "occasion")}
                    />
                  </Grid>
                  {customCheck && (
                    <Grid md={5.5}>
                      <MISTextField
                        id={`${giftId}-customOccasion`}
                        placeholder={T.CUSTOM}
                        value={gifts[giftIndex]?.customOccasion || ""}
                        autoComplete="on"
                        size="small"
                        variant="outlined"
                        name="customOccasion"
                        disabled={disabledCondition()}
                        onChange={(e) => onHandleTableChange(e, giftIndex)}
                      />
                    </Grid>
                  )}
                </Grid>
              </StyledTableCell>
              <StyledTableCell>
                <MISAutocomplete
                  id={`${giftId}-giftType`}
                  key={`${giftId}-giftType`}
                  listDetails={GIFT_TYPE}
                  disabled={disabledCondition()}
                  getByLabelText={(option) => option}
                  value={GIFT_TYPE.find((item) => item === gifts?.find((item) => item.id === giftId)?.giftType) || null}
                  onHandleChange={(e, newValue) => onHandleTableAutoComplete(newValue, giftId, "giftType")}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Grid container justifyContent="center" alignItems="center">
                  <Tooltip title={"Gift History"} placement="top">
                    <IconButton
                      sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                      onClick={() => handleGiftHistoryOpen(get(record, "spoc.name", ""), spocId)}
                    >
                      <BackupTableIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={"Present Gift"} placement="top">
                    <IconButton
                      sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer" }}
                      disabled={!disabledCondition()}
                      onClick={() => handleGiftModalOpen(get(record, "spoc.name", ""), spocId)}
                    >
                      <RedeemIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={userRole === T.COO ? "Accept" : "Recommend"} placement="top">
                    <IconButton
                      disabled={disabledCondition()}
                      onClick={() => handleChangeStatus(giftId, "accept")}
                      sx={{ color: "green", cursor: "pointer" }}
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  {userRole === T.COO && (
                    <Tooltip title={"Reject"} placement="top">
                      <IconButton
                        disabled={disabledCondition()}
                        onClick={() => handleOpenReject(giftId)}
                        sx={{ color: "red", cursor: "pointer" }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
      <PresentGiftModal
        isPresentGiftOpen={isPresentGiftOpen}
        spocName={spocName}
        spocId={spocId}
        handleClose={handleGiftModalClose}
        refreshData={refreshData}
      />
      <GiftHistory
        isGiftHistoryOpen={isGiftHistoryOpen}
        spocName={spocName}
        spocId={spocId}
        handleClose={handleGiftHistoryClose}
      />
      <ConfirmReject
        comment={comment}
        handleChange={handleChange}
        openConfirmSubmit={isRejectedOpen}
        handleSubmit={() => {
          handleChangeStatus(rejectedGiftId, "reject", comment);
          handleCloseReject();
          setLocalState({ comment: "" });
        }}
        handleClose={() => {
          handleCloseReject();
          setLocalState({ comment: "" });
        }}
      />
    </>
  );
};
GiftTableBody.propTypes = {
  allTableRows: PropTypes.array,
};
export default GiftTableBody;
