import { useReducer, useEffect, useCallback } from "react";
import { cloneDeep, sum, debounce, startCase } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { intervalToDuration, format, isValid } from "date-fns";
import { ERROR, SUCCESS } from "theme/colors";

import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import MISLoader from "components/common/MISLoader";
import usePMFetch from "hooks/usePMFetch";

import { useLazyGetAllDepartmentQuery } from "api/members/getDepartmentList";
import { useLazyGetTrainingListQuery } from "api/members/getTrainingList";
import { useAddMemberMutation } from "api/members/addMember";
import { useLazyGetUserByIdQuery } from "api/members/getUserById";
import { useUpdateMemberMutation } from "api/members/updateMember";
import { useCheckAvailableMutation } from "api/members/checkAvailable";

import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";

import T from "T";
import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash";
import { handleError } from "utils/error";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { MISCurrentUser, isEmail, isEmptyString, isMobileNo, isString, isUrl } from "utils/validations";

import MISFooterButton from "components/common/MISFooterButton";
import { useLazyGetActiveProjectListQuery } from "api/projects/getActiveProjectList";
import { MEMBER_MODEL, PORTFOLIO_LINK, PROJECT_ALLOCATION, SKILLS_WITH_PORTFOLIO_LINK } from "./memberModel";

import BasicDetails from "./BasicDetails";
import WorkDetails from "./WorkDetails";
import ProjectDetails from "./ProjectDetails";
import ExitDetails from "./ExitDetails";

import ConfirmCancel from "./ConfirmCancel";
import ConfirmSubmit from "./ConfirmSubmit";
import PortfolioDialog from "./PortfolioDialog";
import { useLazyGetCompanyListQuery } from "api/members/getCompanyList";
import { useLazyGetAllHRBPQuery } from "api/members/getAllHRBP";
import { useLazyGetAllWorkGroupQuery } from "api/members/getAllWorkGroup";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useLazyGetProjectManagerQuery } from "api/projects/getProjectManager";
import { filtersStore } from "slices/filtersSlice";
import { useDispatch } from "react-redux";
import { useLazyGetUserListQuery } from "api/members/getUserList";
const { INITIAL_PAGE } = PAGINATION;

const TOTAL_ALLOCATION_HOURS = 160;
const DEBOUNCE_TIME = 300;
const ROWS_PER_PAGE = 10000;

const Form = ({ getStatus, getFullName }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user } = MISCurrentUser();
  const userName = user.user.userName;

  const url = location.pathname;
  const id = url.includes("edit") && Number(url.replace(/\D+/g, ""));

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tabValue: id ? "4" : "1",
    ...cloneDeep(MEMBER_MODEL),
  });

  const {
    invalidBasicDetailsField,
    tabValue,
    userId,
    fullName,
    jiraName,
    previousJiraName,
    empCode,
    departmentId,
    designation,
    email,
    phone,
    empStatus,
    reportingManager,
    reportingManagerName,
    functionalHeadId,
    empMode,
    joiningDateInNetsmartz,
    careerStartDate,
    totalExpAsOnDate,
    workLocation,
    workMode,
    workModeExceptionReason,
    profileLinkWord,
    shadowTo,
    profileLinkPdf,
    linkedInUrl,
    clientJira,
    isBytLead,
    internalJiraExemption,
    trainingToBeAssigned,
    projectMode,
    trainingName,
    trainingStartDate,
    trainingEndDate,
    secondarySkillTraining,
    comments,
    jiraFrequency,
    previousCompany,
    companyName,
    region,
    workingDays,
    weekDaysList,
    initialWorkingDays,
    isWorking,
    confirmJiraName,
    technologyDetails,
    duplicateCompany,
    previousCompanies,
    projectDetails,
    resignationDate,
    relievingDate,
    releavingComments,
    empCodeExist,
    emailExist,
    mobileNoExist,
    openConfirmCancel,
    openPortfolioLink,
    openConfirmSubmit,
    submitAnyway,
    openConfirm,
    files,
    reSelectedPM,
    profileDocFile,
    portfolioLink,
    companyList,
    isRepMan,
    popIsRepMan,
    HrbpList,
    WorkGroupList,
    hrbpId,
    workGroupId,
    invalidProjectDetailsFields,
    isNewJoiner,
    reportingManagerEmpId,
  } = localState;
  const finalExpAsOnDate = careerStartDate
    ? intervalToDuration({
        start: new Date(careerStartDate),
        end: new Date(),
      })
    : "";

  const [getAllDepartment, { data: departmentList }] = useLazyGetAllDepartmentQuery();
  const [getTrainingList, { data: trainingList }] = useLazyGetTrainingListQuery();
  const [addMember, { isFetching }] = useAddMemberMutation();
  const [getUserById, { isFetching: isUserFetching }] = useLazyGetUserByIdQuery();
  const [updateMember] = useUpdateMemberMutation();
  const [checkAvailable] = useCheckAvailableMutation();
  const [getCompanyList] = useLazyGetCompanyListQuery();
  const [getAllHRBP] = useLazyGetAllHRBPQuery();
  const [getAllWorkGroup] = useLazyGetAllWorkGroupQuery();
  const [getProjectManager] = useLazyGetProjectManagerQuery();
  const [getUserList, { data: userList, isFetching: userLoading }] = useLazyGetUserListQuery();

  useEffect(() => {
    if (empStatus === T.STABLE || empStatus === T.YET_TO_JOIN) {
      setLocalState({ tabValue: "1" });
    }
  }, [empStatus]);
  const [getActiveProjectList, { data: activeProjectList }] = useLazyGetActiveProjectListQuery();

  useEffect(() => {
    getActiveProjectList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE }).unwrap().catch(handleError);
    getAllDepartment({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE }).unwrap().catch(handleError);
    getTrainingList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE }).unwrap().catch(handleError);
    projectMode === T.SHADOW && getUserList({ page: 0, size: 10000, status: T.ACTIVE }).unwrap().catch(handleError);
    getCompanyList()
      .unwrap()
      .then((res) => {
        setLocalState({ companyList: res });
      })
      .catch(handleError);
    getAllHRBP({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ HrbpList: allTableRows });
      });
    getAllWorkGroup({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({ WorkGroupList: allTableRows });
      });
  }, [projectMode]);

  const { projectManagers, functionalManagers, workLocationList, skillList, projectList } = usePMFetch();

  useEffect(() => {
    if (careerStartDate) {
      setLocalState({
        totalExpAsOnDate: `${get(finalExpAsOnDate, "years", "")}.${get(finalExpAsOnDate, "months", "")}`,
      });
    }
  }, [careerStartDate]);

  useEffect(() => {
    getStatus(empStatus);
    getFullName(fullName);
  }, [empStatus, fullName]);
  const isCompanyNameDuplicate = () => {
    const currentCompanyName = previousCompanies[index]?.name?.trim().toLowerCase();
    return companyList.some((company) => company.companyName.trim().toLowerCase() === currentCompanyName);
  };

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const emptyTechDetails = {
    skillId: "",
    skillRating: 0,
    level: "",
    portfolioLink: "",
    isPrimary: false,
    isSecondary: true,
  };

  const emptyCompanyDetails = {
    companyId: null,
    companyName: null,
    duration: null,
    name: null,
  };

  const emptyProjectDetails = {
    projectId: "",
    startDate: null,
    endDate: null,
    projectManagerId: "",
    hoursAllocation: "",
    hoursAllocationCustom: "",
    isPrimary: false,
    isSecondary: true,
    comment: "",
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = (id) => {
    getUserById({ id })
      .unwrap()
      .then((res) => {
        const record = get(res, "results", {});
        const training = get(record, "userTraining[0]", {});
        const skills = get(record, "userSkills", []);
        const projects = get(record, "projects", []);
        const secondarySkillSetTraining = get(record, "secondarySkillTraining.id", "");
        const companyMapping = get(record, "companyMapping", [emptyCompanyDetails]);
        const memberRecords = {
          userId: get(record, "id", ""),
          fullName: get(record, "userName", ""),
          empCode: get(record, "employeeCode", ""),
          empStatus: get(record, "empStatus", ""),
          departmentId: get(record, "userDepartment.id", ""),
          designation: get(record, "userDesignation", ""),
          email: get(record, "userEmailId", ""),
          phone: get(record, "userMobileNo", ""),
          jiraName: get(record, "jiraName", ""),
          previousJiraName: get(record, "jiraName", ""),
          reportingManager: get(record, "reportingManager.id", ""),
          reportingManagerEmpId: get(record, "reportingManager.empId", ""),
          reportingManagerName: get(record, "reportingManager.name", ""),
          functionalHeadId: get(record, "functionalHead.id", ""),
          empMode: get(record, "empMode", ""),
          joiningDateInNetsmartz: get(record, "joiningDateTime", null),
          careerStartDate: get(record, "careerStartDateTime", null),
          totalExpAsOnDate: `${get(record, "expInYears", 0)}.${get(record, "expInMonths", 0)} ${T.YEARS.toLowerCase()}`,
          workLocation: get(record, "workLocation.id", ""),
          workMode: get(record, "workMode", ""),
          hrbpId: get(record, "hrbp.id", ""),
          workGroupId: get(record, "workGroup.id", ""),
          workModeExceptionReason: get(record, "wfoExceptionReason", ""),
          profileLinkWord: get(record, "profileLinkWord", ""),
          profileLinkPdf: get(record, "profileLinkPdf", ""),
          profileDocFile: get(record, "profileDocFile", null),
          employeeFeedbacks: get(record, "employeeFeedbacks", null),
          clientJira: get(record, "clientJira", false),
          linkedInUrl: get(record, "linkedInUrl", ""),
          isBytLead: get(record, "isBytLead", ""),
          isRepMan: get(record, "isProjectManager", false),
          popIsRepMan: get(record, "isProjectManager", false),
          internalJiraExemption: get(record, "internalJiraExemption", ""),
          trainingToBeAssigned: get(record, "isTrainingToBeAssigned", ""),
          projectMode: get(record, "projectMode", ""),
          trainingName: get(training, "trainingName", ""),
          trainingStartDate: get(training, "startDate", null),
          trainingEndDate: get(training, "endDate", null),
          secondarySkillTraining: { skillId: secondarySkillSetTraining },
          comments: get(record, "comments", ""),
          previousCompany: get(record, "previousCompany", ""),
          jiraFrequency: get(record, "jiraFrequency", ""),
          region: get(record, "region", ""),
          workingDays: get(record, "workingDays", []),
          portfolioLink: get(record, "portfolioLink", ""),
          shadowTo: get(record, "shadowTo", ""),
          technologyDetails:
            skills.length > 0
              ? skills.map((skill) => ({
                  skillId: get(skill, "skills.id", ""),
                  skillRating: get(skill, "rating", 0),
                  level: getValueLevel(get(skill, "rating", 0)),
                  // portfolioLink: get(skill, "portfolioLink", ""),
                  isPrimary: get(skill, "primary", false),
                  isSecondary: get(skill, "secondary", false),
                }))
              : [emptyTechDetails],

          previousCompanies:
            companyMapping.length > 0
              ? companyMapping.map((comp) => ({
                  companyId: get(comp, "companyId", null),
                  duration: get(comp, "duration", null),
                }))
              : [emptyCompanyDetails],

          projectDetails:
            projects.length > 0
              ? projects.map((project) => ({
                  projectId: get(project, "project.id", ""),
                  startDate: get(project, "startDate", null),
                  endDate: get(project, "endDate", null),
                  projectManagerId: get(get(project, "project", {}), "projectManager.id", ""),
                  hoursAllocationCustom: PROJECT_ALLOCATION.includes(get(project, "hoursAllocation", ""))
                    ? ""
                    : get(project, "hoursAllocation", ""),
                  hoursAllocation: PROJECT_ALLOCATION.includes(get(project, "hoursAllocation", ""))
                    ? get(project, "hoursAllocation", "")
                    : T.CUSTOM,
                  isPrimary: get(project, "primary", false),
                  isSecondary: get(project, "secondary", false),
                  comment: get(project, "comment", ""),
                }))
              : [emptyProjectDetails],
          resignationDate: get(record, "resignationDate", null),
          relievingDate: get(record, "releavingDate", null),
          releavingComments: get(record, "releavingComments", ""),
          isNewJoiner: get(record, "isNewJoiner", false),
        };
        setLocalState(memberRecords);
      })
      .catch(handleError);
  };
  const disableCondition = () => id && empStatus !== T.STABLE && empStatus !== T.YET_TO_JOIN;
  const handleTabChange = (event, newValue) => {
    setLocalState({ tabValue: newValue });
  };

  const onHandleTrainingChange = (type, value) => {
    secondarySkillTraining[type] = value;
    setLocalState({ secondarySkillTraining });
  };

  const onHandleNewAutoCompleteChange = (type, value) => {
    if (projectMode === T.SHADOW) {
      setLocalState({ [type]: value });
    } else {
      setLocalState({ ["shadowTo"]: "" });
    }
    setLocalState({ [type]: value });
  };

  const onHandleCompanyChange = (newValue) => {
    setLocalState({ previousCompany: newValue, companyName: "" });
  };

  const handleJiraNameChange = () => {
    setLocalState({ confirmJiraName: !confirmJiraName });
  };
  const handleRevertChange = () => {
    setLocalState({ jiraName: previousJiraName });
    handleJiraNameChange();
  };
  const onHandleChange = (event) => {
    const { name, value } = event.target;
    if (name === "workLocation") {
      const workLocObj = get(workLocationList, "results", []).find((data) => data.id === value);
      const workLocName = get(workLocObj, "workLocationName", "");
      if (workLocName === T.WFH) {
        setLocalState({ workMode: T.WFH });
      }
    }
    setLocalState({ [name]: value });
  };

  // const onHandleFileChange = (event) => {
  //   const { name, files } = event.target;
  //   setLocalState({ [name]: get(files, "[0]", "") });
  // };
  const onHandleDateChange = (newValue, type) => {
    if (newValue !== null) {
      const validDate = new Date(newValue);
      if (isValid(validDate)) {
        setLocalState({ [type]: getBEDateFormat(validDate) });
      }
    } else {
      setLocalState({ [type]: null });
    }
  };

  const onHandleMultiChange = (event, index) => {
    const { name, value } = event.target;
    previousCompanies[index][name] = value;
    setLocalState({ previousCompanies });
  };

  const onHandleTechAddMore = () => {
    technologyDetails.push(emptyTechDetails);
    setLocalState({ technologyDetails });
  };

  const onHandlePrevCompAddMore = () => {
    previousCompanies.push(emptyCompanyDetails);
    setLocalState({ previousCompanies });
  };

  const updateRm = (updatedRM) => {
    setLocalState({ reportingManager: updatedRM.id });
  };

  const onHandleProjectAddMore = () => {
    projectDetails.push(emptyProjectDetails);
    setLocalState({ projectDetails });
  };

  const onHandleProjectRemove = (index) => {
    projectDetails.splice(index, 1);
    setLocalState({ projectDetails });
  };

  const onHandleResetProject = () => {
    setLocalState({ projectDetails: [emptyProjectDetails] });
  };

  const onHandleTechRemove = (index) => {
    if (technologyDetails[index] && SKILLS_WITH_PORTFOLIO_LINK.includes(technologyDetails[index].skillId)) {
      technologyDetails.splice(index, 1);
      setLocalState({ technologyDetails, portfolioLink: null });
    } else {
      technologyDetails.splice(index, 1);
      setLocalState({ technologyDetails });
    }
  };

  const onHandlePrevCompRemove = (index) => {
    if (previousCompanies[index]) {
      previousCompanies.splice(index, 1);
      setLocalState({ previousCompanies });
    } else {
      previousCompanies.splice(index, 1);
      setLocalState({ previousCompanies });
    }
  };
  const resetName = (index) => {
    if (previousCompanies[index]) {
      previousCompanies[index].name = "";
      previousCompanies[index].duration = "";
      setLocalState({ previousCompanies });
    }
  };

  const setDuplicateCompany = (value) => {
    setLocalState({ duplicateCompany: value });
  };
  const getValueLevel = (value) => {
    if (value <= 2.5) {
      return T.BEGINNER;
    } else if (value > 2.5 && value < 4) {
      return T.INTERMEDIATE;
    } else if (value >= 4) {
      return T.EXPERT;
    }
  };

  const onHandleTechChange = (index, event, newValue, type) => {
    const { name, value } = event.target;
    if (type === "level") {
      technologyDetails[index][type] = get(newValue, "label", "");
      technologyDetails[index]["skillRating"] = parseFloat(get(newValue, "value", 0));
    } else {
      technologyDetails[index][name] = name === "skillRating" ? parseFloat(value) : value;
      technologyDetails[index]["level"] = getValueLevel(value);
    }
    setLocalState({ technologyDetails });
  };

  const onHandleCheckboxChange = (index, event, type) => {
    const { name, checked } = event.target;

    if (type === T.TECHNOLOGY) {
      technologyDetails[index][name] = checked;
      technologyDetails[index].isSecondary = !checked;
      setLocalState({ technologyDetails });
    } else {
      const checkPrimary = projectDetails.map((project) => project.isPrimary).includes(true);

      if (checkPrimary) {
        //  checkAndUpdateReportManager(projectDetails[index]);
        projectDetails.map((project) => {
          project.isPrimary = false;
          project.isSecondary = true;
        });
      }

      projectDetails[index][name] = checked;
      projectDetails[index].isSecondary = !checked;

      setLocalState({ projectDetails });
    }
  };

  const handleWorkingDaysCheckboxChange = (event, index) => {
    const { value, checked } = event.target;
    if (checked) {
      let newWorkingDays = [...workingDays];
      newWorkingDays = [...newWorkingDays, value];
      setLocalState({ workingDays: newWorkingDays });
    } else {
      let newWorkingDays = [...workingDays];
      newWorkingDays = newWorkingDays.filter((item) => item !== value);
      setLocalState({ workingDays: newWorkingDays });
    }
  };

  const onHandleProjectChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    projectDetails[index][name] = type === "checkbox" ? checked : name !== "hoursAllocationCustom" ? value : parseInt(value);
    setLocalState({ projectDetails });
  };

  // const checkAndUpdateReportManager = (currentRecord) => {
  //   const endDate = new Date(get(currentRecord, "endDate", ""));
  //   const startDate = new Date(get(currentRecord, "startDate", ""));
  //   const differenceInMonth =
  //     isValid(startDate) && isValid(endDate)
  //       ? differenceInMonths(endDate, startDate)
  //       : 0;
  //   if (differenceInMonth > 2) {
  //     const currentProject = projectListDetails.find(
  //       (project) => project.id === currentRecord.projectId
  //     );

  //     setLocalState({
  //       reportingManager: get(currentProject, "projectManager.id", ""),
  //     });
  //   }
  // };

  const onHandleProjectDateChange = (newValue, type, index) => {
    const validDate = newValue ? new Date(newValue) : null;
    projectDetails[index][type] = validDate && isValid(validDate) ? getBEDateFormat(validDate) : null;
    setLocalState({ projectDetails });
    // get(projectDetails[index],"isPrimary",false) && checkAndUpdateReportManager(projectDetails[index]);
  };

  const onHandleAutoCompleteChange = (index, type, value, parent) => {
    const hasRecentFeedback = localState.employeeFeedbacks?.find(
      (feedback) => feedback.createdBy === userName && new Date(feedback.createdAt).getTime() >= Date.now() - 24 * 60 * 60 * 1000,
    );

    if (type === "reportingManager" && value !== "") {
      if (!hasRecentFeedback) {
        setLocalState({ openConfirm: true });
      }
      const foundValue = get(projectManagers, "results", {}).find((val) => {
        return val.id === value;
      });
      setLocalState({ reSelectedPM: foundValue });
    }

    switch (parent) {
      case T.SKILL:
        technologyDetails[index][type] = value;
        setLocalState({ technologyDetails });
        if (value === PORTFOLIO_LINK) {
          handleOpenPortModal();
        }
        return;
      case T.PROJECT:
        projectDetails[index][type] = value;
        // get(projectDetails[index],"isPrimary",false) && checkAndUpdateReportManager(projectDetails[index]);
        setLocalState({ projectDetails });
        return;
      case T.DEPARTMENT:
        setLocalState({ [type]: value });
        return;
      case T.REPORTING_MANAGER:
        setLocalState({ [type]: value });
        return;
      case T.FUNCTIONAL_MANAGER:
        setLocalState({ [type]: value });
        break;

      case T.PREVIOUS_COMPANY:
        previousCompanies[index][type] = value;
        setLocalState({ previousCompanies });
        break;
      default:
    }
  };

  const handleDialogClose = () => {
    if (id) {
      fetchData(id);
    }
    setLocalState({ openConfirm: false, reSelectedPM: null });
  };
  // const allocationHoursLeft =
  //   TOTAL_ALLOCATION_HOURS -
  //   sum(projectDetails.map((data) => data.hoursAllocation).filter((n) => n));
  const allocationHoursLeft =
    TOTAL_ALLOCATION_HOURS -
    sum(
      projectDetails
        .filter((data) => new Date(data.endDate) > new Date())
        .map((data) => (data.hoursAllocation !== "Custom" ? data.hoursAllocation : data.hoursAllocationCustom))
        .filter((n) => n),
    );

  const isRecordAvailable = useCallback(
    debounce((event) => {
      const { name, value } = event.target;
      const payload = {
        [name === "phone" ? "mobileNo" : name]: value,
      };
      if (name === "empCode") {
        setLocalState({ empCodeExist: false });
      }
      if (name === "email") {
        setLocalState({ emailExist: false });
      }
      if (name === "phone") {
        setLocalState({ mobileNoExist: false });
      }
      checkAvailable(payload)
        .unwrap()
        .then((res) => {
          if (id) {
            getUserById({ id })
              .unwrap()
              .then((resp) => {
                const userData = get(resp, "results", {});
                const userEmpCode = get(userData, "employeeCode", "");
                const userMobile = get(userData, "userMobileNo", "");
                const userEmail = get(userData, "userEmailId", "");
                if (res) {
                  if (name === "empCode") {
                    if (userEmpCode === value) {
                      setLocalState({ empCodeExist: false });
                    } else {
                      setLocalState({ empCodeExist: res });
                      toast.error(`${T.EMP_CODE} Already Exist`);
                    }
                  }
                  if (name === "email") {
                    if (userEmail === value) {
                      setLocalState({ emailExist: false });
                    } else {
                      setLocalState({ emailExist: res });
                      toast.error(`${T.EMAIL_ID} Already Exist`);
                    }
                  }
                  if (name === "phone") {
                    if (userMobile === value) {
                      setLocalState({ mobileNoExist: false });
                    } else {
                      setLocalState({ mobileNoExist: res });
                      toast.error(`${T.PHONE} ${T.NUMBER} Already Exist`);
                    }
                  }
                  // toast.error( `
                  // ${name==="empCode"?T.EMP_CODE
                  // :
                  // name==="phone"?`${T.PHONE} ${T.NUMBER} `
                  // :
                  // name==="email"&& T.EMAIL_ID  } Already Exist`);
                }
              })
              .catch(handleError);
          } else if (res) {
            if (name === "empCode") {
              setLocalState({ empCodeExist: res });
            }
            if (name === "email") {
              setLocalState({ emailExist: res });
            }
            if (name === "phone") {
              setLocalState({ mobileNoExist: res });
            }
            toast.error(`
              ${
                name === "empCode" ? T.EMP_CODE : name === "phone" ? `${T.PHONE} ${T.NUMBER} ` : name === "email" && T.EMAIL_ID
              } Already Exist`);
          }
        })
        .catch(handleError);
    }, DEBOUNCE_TIME),
    [],
  );

  const renderContent = () => ({
    id,
    empStatus,
    clientJira,
    isBytLead,
    jiraName,
    previousJiraName,
    internalJiraExemption,
    projectMode,
    trainingToBeAssigned,
    trainingName,
    trainingStartDate,
    trainingEndDate,
    secondarySkillTraining,
    comments,
    technologyDetails,
    skillList,
    trainingList,
    companyList,
    previousCompany,
    companyName,
    jiraFrequency,
    region,
    workingDays,
    weekDaysList,
    initialWorkingDays,
    isWorking,
    confirmJiraName,
    isRepMan,
    userList: get(userList, "results", []),
    userLoading,
    shadowTo,
    onHandleChange,
    onHandleTrainingChange,
    handleJiraNameChange,
    handleRevertChange,
    onHandleCheckboxChange,
    handleWorkingDaysCheckboxChange,
    onHandleDateChange,
    onHandleTechAddMore,
    onHandleTechChange,
    setDuplicateCompany,
    onHandleTechRemove,
    onHandleAutoCompleteChange,
    onHandleNewAutoCompleteChange,
    onHandleCompanyChange,
    files,
    portfolioLink,
    onHandlePrevCompAddMore,
    onHandlePrevCompRemove,
    previousCompanies,
    onHandleMultiChange,
    resetName,
    isNewJoiner,
  });

  const renderBasicContent = () => ({
    id,
    empStatus,
    fullName,
    empCode,
    departmentId,
    designation,
    email,
    phone,
    reportingManager,
    reportingManagerEmpId,
    reportingManagerName,
    functionalHeadId,
    empMode,
    joiningDateInNetsmartz,
    careerStartDate,
    totalExpAsOnDate,
    workLocation,
    workMode,
    workModeExceptionReason,
    linkedInUrl,
    profileLinkWord,
    profileLinkPdf,
    departmentList,
    projectManagers,
    functionalManagers,
    workLocationList,
    isRecordAvailable,
    empCodeExist,
    emailExist,
    mobileNoExist,
    reSelectedPM,
    HrbpList,
    WorkGroupList,
    hrbpId,
    workGroupId,
    onHandleNewAutoCompleteChange,
    onHandleChange,
    // onHandleFileChange,
    onHandleDateChange,
    onHandleAutoCompleteChange,
    open: openConfirm,
    handleRedirectFeedback,
    handleDialogClose,
    files,
    handleFilesChange,
    updateRm,
    profileDocFile,
    invalidBasicDetailsField,
    validationFieldsBasicDetails,
  });

  const renderProjectContent = () => ({
    id,
    empStatus,
    projectDetails,
    activeProjectList,
    projectList,
    reportingManager,
    projectManagers,
    allocationHoursLeft,
    getBEDateFormat,
    onHandleChange,
    onHandleCheckboxChange,
    onHandleProjectDateChange,
    onHandleAutoCompleteChange,
    onHandleProjectAddMore,
    onHandleProjectChange,
    onHandleProjectRemove,
    onHandleResetProject,
    validationFieldsProjectDetails,
    invalidProjectDetailsFields,
  });

  const handleClose = () => {
    navigate("/app/members", {
      state: {
        showActive: !(
          empStatus === T.RELIEVED ||
          empStatus === T.DID_NOT_JOIN ||
          empStatus === T.SEPARATED ||
          empStatus === T.ABSCONDED
        ),
      },
    });
    if (popIsRepMan !== isRepMan) {
      getProjectManager({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ projectManagers: res }));
        });
    }
  };

  const getAllocationHrs = (allocationHoursLeft) => {
    if (allocationHoursLeft === 160) {
      return 1;
    }
    if (allocationHoursLeft > 0) {
      return 2;
    }
    return 0;
  };

  const projects = projectDetails.filter((data) => data.projectId !== "" && data.startDate !== "" && data.endDate !== "");

  const userSkills = technologyDetails.filter((data) => data.skillId !== "");

  const getPayload = () => {
    const prevComps = previousCompanies
      .filter((it) => it.companyName === "Other")
      .map((it) => ({ name: it.name.trim(), duration: it.duration }));
    if (prevComps) {
      var valueArr = prevComps.map(function (item) {
        return item.name.toLowerCase();
      });
      var isDuplicate = valueArr.some(function (item, idx) {
        return valueArr.indexOf(item) !== idx;
      });

      if (isDuplicate) {
        toast.error(T.DUPLICATE_COMPANY_NAME);
        return;
      }
    }

    const payload = {
      careerStartDate,
      clientJira,
      comments,
      jiraFrequency,
      region,
      workingDays,
      departmentId,
      designation,
      email,
      empCode,
      functionalHeadId,
      empMode,
      isBytLead,
      internalJiraExemption,
      isAvailabilitySheet: allocationHoursLeft > 0,
      isPartialAllocation: getAllocationHrs(allocationHoursLeft),
      joiningDate: joiningDateInNetsmartz ? joiningDateInNetsmartz.split(" ")[0] : null,
      locationId: workLocation,
      mobileNo: phone,
      projectMode,
      secondarySkillTrainingId: secondarySkillTraining.skillId,
      reportingManager,
      expInYears: get(finalExpAsOnDate, "years", 0),
      expInMonths: get(finalExpAsOnDate, "months", 0),
      trainingToBeAssigned,
      userName: startCase(fullName),
      jiraName,
      previousCompany: companyName ? null : get(previousCompany, "id", ""),
      companyName,
      wfoExceptionReason: workModeExceptionReason,
      profileLinkWord,
      profileLinkPdf,
      linkedInUrl,
      workMode,
      projects,
      userSkills,
      hrbpId,
      shadowTo: shadowTo !== "" ? shadowTo : null,
      isProjectManager: isRepMan,
      workedGroupId: workGroupId,
      portfolioLink,
      companyMapping: previousCompanies
        .filter((it) => it.companyName !== "Other" && it.companyId !== "" && it.companyId !== null)
        .map((it) => ({ companyId: it.companyId, duration: it.duration ? it.duration : null })),
      userPreviousCompanies: previousCompanies
        .filter((it) => it.companyName === "Other")
        .map((it) => ({ name: it.name.trim(), duration: it.duration })),
      isNewJoiner,
    };

    projects.map((project) =>
      project.hoursAllocation === T.CUSTOM && project.hoursAllocationCustom !== ""
        ? (project.hoursAllocation = project.hoursAllocationCustom)
        : project,
    );

    if (projects.length > 0) {
      payload.projects = projects.filter((project) => delete project.hoursAllocationCustom);
    }

    if (userSkills.length > 0) {
      payload.userSkills = userSkills;
    }

    if (trainingToBeAssigned) {
      payload.training = {
        endDate: trainingEndDate,
        startDate: trainingStartDate,
        trainingName: startCase(trainingName),
      };
    }

    if (userId || id) {
      payload.id = userId || id;
      payload.resignationDate = resignationDate;
      payload.releavingComments = releavingComments;
      payload.releavingDate = relievingDate;
    }

    return payload;
  };

  // ####################### Validations #########################

  // validations for basic details

  const handleBasicDetailValidation = () => {
    const invalidBasicDetailsFields = validationFieldsBasicDetails.filter((field) => !field.value).map((field) => field.name);
    if (invalidBasicDetailsFields.length > 0) {
      setLocalState({ invalidBasicDetailsField: invalidBasicDetailsFields });
      return true;
    }
  };

  const validationFieldsBasicDetails = [
    {
      name: "fullName",
      value: fullName && typeof fullName === "string" && /^[A-Za-z\s]+$/.test(fullName.trim()) && fullName.trim() !== "",
    },
    {
      name: "empCode",
      value: typeof empCode === "string" && empCode.trim() !== "" && /^[A-Za-z0-9]+$/.test(empCode.trim()) && !empCodeExist,
    },
    {
      name: "departmentId",
      value: departmentId,
    },
    {
      name: "designation",
      value: typeof designation === "string" && designation.trim() !== "" && /^[A-Za-z\s\-\.]+$/.test(designation.trim()),
    },
    {
      name: "email",
      value: email && isEmail(email) && !emailExist,
    },
    {
      name: "phone",
      value: phone && isMobileNo(phone) && !mobileNoExist,
    },
    {
      name: "reportingManager",
      value: reportingManager,
    },
    {
      name: "functionalHeadId",
      value: functionalHeadId,
    },
    {
      name: "careerStartDate",
      value: careerStartDate,
    },
    {
      name: "joiningDateInNetsmartz",
      value: joiningDateInNetsmartz,
    },
    {
      name: "workLocation",
      value: workLocation,
    },
    {
      name: "workMode",
      value: workMode,
    },
  ];

  // validation for work details

  const handleWorkDetailsValidations = () => {
    const basicCondition = !workingDays || workingDays.length === 0 || (trainingToBeAssigned ? !trainingName : false);
    const portCheck = technologyDetails?.map((tech) => tech.skillId).includes(PORTFOLIO_LINK);
    const portfolioLinkCondition = portCheck ? !portfolioLink || !isUrl(portfolioLink) : false;

    return basicCondition || portfolioLinkCondition || duplicateCompany;
  };

  // validations for project details

  const validationFieldsProjectDetails = [
    { name: "startDate", value: (index) => !projects[index]["startDate"] },
    { name: "endDate", value: (index) => !projects[index]["endDate"] },
    { name: "hoursAllocation", value: (index) => !projects[index]["hoursAllocation"] },
    {
      name: "hoursAllocationCustom",
      value: (index) => (projects[index]["hoursAllocation"] === "Custom" ? !projects[index]["hoursAllocationCustom"] : false),
    },
  ];

  const handleProjectDetailValidation = () => {
    const invalidProjectDetailsFields = projects?.map((project, index) =>
      validationFieldsProjectDetails?.filter((field) => field.value(index))?.map((field) => field.name),
    );
    const someInvalidProjects = projects?.filter((project, index) =>
      validationFieldsProjectDetails?.some((field) => field?.value(index)),
    );
    if (someInvalidProjects.length > 0) {
      setLocalState({ invalidProjectDetailsFields: invalidProjectDetailsFields });
      return true;
    }
    return false;
  };

  const handleAdd = (payload) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result.split(",")[1];
        const updatedFiles = base64File;
        const fileName = files[0].name;
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
        addMember({ ...payload, profileDocFile: updatedFiles, docFileType: fileType })
          .unwrap()
          .then(() => {
            toast.success(T.USER_CREATED_SUCCESSFULLY);
            handleClose();
          })
          .catch(handleError);
      };
      reader.readAsDataURL(files[0]);
    } else {
      addMember(payload)
        .unwrap()
        .then(() => {
          toast.success(T.USER_CREATED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    }
  };

  const handleUpdate = (payload) => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result.split(",")[1];
        const updatedFiles = base64File;
        const fileName = files[0].name;
        const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
        updateMember({ ...payload, profileDocFile: updatedFiles, docFileType: fileType })
          .unwrap()
          .then(() => {
            toast.success(T.USER_UPDATED_SUCCESSFULLY);
            handleClose();
          })
          .catch(handleError);
      };
      reader.readAsDataURL(files[0]);
    } else {
      updateMember(payload)
        .unwrap()
        .then(() => {
          toast.success(T.USER_UPDATED_SUCCESSFULLY);
          handleClose();
        })
        .catch(handleError);
    }
  };

  const handleSubmit = () => {
    if (handleBasicDetailValidation() && (empStatus === "" || empStatus === T.STABLE || empStatus === T.YET_TO_JOIN)) {
      setLocalState({ tabValue: "1" });
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return;
    }

    if (handleWorkDetailsValidations()) {
      setLocalState({ tabValue: "2" });
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return;
    }
    if (handleProjectDetailValidation()) {
      setLocalState({ tabValue: "3" });
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return;
    } else {
      if (tabValue === "3" && !submitAnyway) {
        openSubmitModal();
      } else {
        const payload = getPayload();
        const filteredPayload = Object.keys(payload)
          .filter((f) => payload[f])
          .reduce((r, i) => {
            r[i] = payload[i];
            return r;
          }, {});
        if (id) {
          handleUpdate({ ...filteredPayload, portfolioLink, isBytLead, isProjectManager: isRepMan });
        } else {
          handleAdd(filteredPayload);
        }
      }
    }
  };

  const handleRedirectFeedback = (userid) => {
    const employeeFeedbacks = localState.employeeFeedbacks;
    const userFeedback = employeeFeedbacks?.find(
      (feedback) => feedback.createdBy === userName && new Date(feedback.createdAt).getTime() >= Date.now() - 24 * 60 * 60 * 1000,
    );
    if (userFeedback) {
      toast.error("Feedback for this user already submitted by you within last 24 hours.", { autoClose: 5000 });
    } else {
      navigate(`/app/member/feedback/form/${userid}`, { state: { reSelectedPM: reSelectedPM } });
    }
  };
  const handleSaveNContinue = () => {
    setLocalState({ tabValue: (parseInt(tabValue) + 1).toString() });
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };
  const handleOpenPortModal = () => {
    setLocalState({ openPortfolioLink: true });
  };

  const handleSubmitPortal = (portfolioLink) => {
    setLocalState({ portfolioLink: portfolioLink });
    if (isUrl(portfolioLink)) {
      setLocalState({ openPortfolioLink: false });
    }
  };

  const confrmCancelPortModal = () => {
    if (technologyDetails.map((tech) => tech.skillId).includes(PORTFOLIO_LINK)) {
      setLocalState({ openPortfolioLink: false });
    }
    setLocalState({ portfolioLink: "" });
  };

  const openSubmitModal = () => {
    setLocalState({
      openConfirmSubmit: !openConfirmSubmit,
      submitAnyway: !submitAnyway,
    });
  };

  const closeSubmitModal = () => {
    setLocalState({
      openConfirmSubmit: !openConfirmSubmit,
      tabValue: (parseInt(tabValue) - 1).toString(),
    });
  };
  const submitCondition =
    (tabValue === "4" && id) ||
    (tabValue === "3" && id && empStatus === T.STABLE) ||
    (tabValue === "3" && id && empStatus === T.YET_TO_JOIN) ||
    (tabValue === "3" && !id);

  const handleFilesChange = (updatedFiles) => {
    setLocalState({ ...localState, files: updatedFiles });
  };

  return (
    <TabContext value={tabValue}>
      {(isFetching || isUserFetching) && <MISLoader />}
      <TabList
        variant="scrollable"
        onChange={handleTabChange}
        aria-label="lab API tabs example"
        sx={{
          "& .MuiTab-root.Mui-selected": {
            color: BACKGROUND.black,
          },
          "& .MuiTabs-indicator": {
            left: 0,
            backgroundColor: NETSMARTZ_THEME_COLOR,
          },
          "& .MuiTab-root": {
            fontWeight: 600,
          },
        }}
      >
        <Tab label={T.BASIC_DETAILS} value="1" />
        <Tab label={T.WORK_DETAILS} value="2" />
        <Tab label={T.PROJECT_DETAILS} value="3" />
        {(id || userId) && [T.DID_NOT_JOIN, T.RESIGNED, T.ABSCONDED, T.RELIEVED].includes(empStatus) && (
          <Tab label={T.EXIT_DETAILS} value="4" />
        )}
      </TabList>

      <Box
        sx={{
          height: "calc(100vh - 275px)",
          overflowY: "auto",
        }}
      >
        <TabPanel value="1" sx={{ pb: 0 }}>
          <BasicDetails {...renderBasicContent()} />
        </TabPanel>
        <TabPanel value="2">
          <WorkDetails {...renderContent()} />
        </TabPanel>
        <TabPanel value="3">
          <ProjectDetails {...renderProjectContent()} />
        </TabPanel>
        <TabPanel value="4">
          <ExitDetails
            id={id}
            empStatus={empStatus}
            resignationDate={resignationDate}
            relievingDate={relievingDate}
            releavingComments={releavingComments}
            onHandleChange={onHandleChange}
            onHandleDateChange={onHandleDateChange}
          />
        </TabPanel>
      </Box>
      <MISFooterButton
        id={id}
        tab={tabValue}
        empStatus={empStatus}
        proceedButtonText={submitCondition ? T.SUBMIT : T.SAVE_AND_CONTINUE}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleEditSubmit={handleSubmit}
        handleSubmit={submitCondition ? handleSubmit : handleSaveNContinue}
      />
      <ConfirmSubmit
        proceedButtonText={T.SUBMIT_ANYWAY}
        cancelButtonText={T.OOPS_I_FORGOT}
        dialogMessageText={T.REMEMBER_TO_CHANGE_MEMBER_DETAILS}
        openConfirmSubmit={openConfirmSubmit}
        handleClose={closeSubmitModal}
        handleSubmit={handleSubmit}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
      <PortfolioDialog
        disableCondition={disableCondition}
        onHandleChange={onHandleChange}
        openPortfolioLink={openPortfolioLink}
        confrmCancelPortModal={confrmCancelPortModal}
        handleClose={handleClose}
        handleSubmitPortal={handleSubmitPortal}
        portfolioLink={portfolioLink}
      />
    </TabContext>
  );
};

export default Form;
