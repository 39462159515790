import { Children, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import { toast } from "react-toastify";

import { TableRow, TableCell, TableBody, styled } from "@mui/material";

import { useRemoveRoleMutation } from "api/members/removeRole";

import { getAllTableMenus } from "settings/constants/members";
import { getMemberColumnData, TitleTypography } from "utils/members";
import { BACKGROUND } from "theme/colors";
import AssignedCourseDialog from "components/Training/AssignedTraining/AssignedCourseDialog";

import T from "T";

import { get } from "utils/lodash";
import memo from "utils/react";
import { handleError } from "utils/error";
import { GET_SIZE } from "utils/responsive";
import DeleteUser from "components/Members/Table/DeleteUser";
import RevokeMemberResignation from "components/Members/Table/RevokeMemberResignation";
import DeactivateUser from "components/Members/Table/DeactivateUser";
import AssignRole from "components/Members/Table/AssignRole";
import { useLazyGetSubRoleQuery } from "api/members/getSubRoles";
import Constants from "Constants";
import { MISCurrentUser } from "utils/validations";
import { useAssignMockInterviewMutation } from "api/MockInterview/assignMockInterview";
import { useNavigate } from "react-router-dom";
import { COL_KEYS } from "./onBenchConstants";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
}));

const MISTableBody = ({
  columns = [],
  lockedColumns = [],
  records = [],
  currentScrollPosition = 0,
  refreshUserAction = noop,
  refreshMemberTable = noop,
}) => {
  const { isXs } = GET_SIZE();
  const navigate = useNavigate();
  const [removeRole] = useRemoveRoleMutation();
  const [getSubRole] = useLazyGetSubRoleQuery();
  const [assignMockInterview] = useAssignMockInterviewMutation();
  const { SERVER_URL } = Constants;
  const { sessionToken, user } = MISCurrentUser();
  const user_id = get(user, "id", "");
  const userRole = get(user, "role", "");
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    assignRole: false,
    assignEditRole: false,
    assignedCourse: false,
    assignedLD: false,
    deactivateMember: false,
    revokeResignation: false,
    deleteUser: false,
    userId: "",
    userName: "",
    subRolesMapping: [],
  });
  const {
    assignRole,
    subRolesMapping,
    assignEditRole,
    assignedCourse,
    assignedLD,
    deactivateMember,
    revokeResignation,
    deleteUser,
    userId,
    userName,
  } = localState;

  useEffect(() => {
    getSubRole()
      .unwrap()
      .then((res) => {
        setLocalState({ subRolesMapping: res });
      });
  }, []);

  const handleAssignDialog = (userId) => {
    setLocalState({ assignRole: !assignRole, userId });
  };
  const handleCloseDialog = () => {
    setLocalState({ assignRole: false, assignEditRole: false });
  };
  const handleAssignedCourseDialog = (userId, userName) => {
    setLocalState({ userName: userName, assignedCourse: !assignedCourse, userId });
  };
  const handleAssignedLDDialog = (userId, userName) => {
    setLocalState({ userName: userName, assignedLD: !assignedLD, userId });
  };
  const handleEditAssignDialog = (userId) => {
    setLocalState({ assignEditRole: !assignEditRole, userId });
    // refreshMemberTable();
  };

  const handleDeactivateDialog = (userId) => {
    setLocalState({ deactivateMember: !deactivateMember, userId });
  };

  const handleRevokeResignationDialog = (userId) => {
    setLocalState({ revokeResignation: !revokeResignation, userId });
  };

  const handleDeleteUserDialog = (userId) => {
    setLocalState({ deleteUser: !deleteUser, userId });
  };
  const handleMockInterviewToolLink = (id) => {
    assignMockInterview({ id })
      .unwrap()
      .then(() => toast.success(T.MOCK_INTERVIEW_ASSIGNED_SUCCESSFULLY))
      .catch(handleError);
  };
  const handleAddTechnicalAssignment = (id) => {
    navigate(`/app/member-assignments/${id}`);
  };
  const handleAssignTechnicalInterview = (id) => {
    navigate(`/app/technical-interview/${id}`);
  };

  const handleRemoveRoleDialog = (id) => {
    removeRole({ memberId: id })
      .unwrap()
      .then(() => {
        toast.success(T.ROLE_REMOVED_STATEMENT);
      })
      .catch(handleError);
  };

  const handleExport = (email) => {
    const url = `${SERVER_URL}/user/download/profileDocFile?userEmail=${email}`;
    try {
      window.location.href = url;
    } catch (error) {
      handleError(error);
    }
  };

  const getMenu = (record) => {
    const isNewJoiner = get(record, "isNewJoiner", false);

    const finalMenus = getAllTableMenus()
      .filter((menu) => menu.permission)
      .filter((menu) => {
        return !isNewJoiner ? menu.title !== T.NEW_JOINER_LEARNING_PATH : menu.title !== T.APPRAISAL_LEARNING_PATH;
      });

    let values = [];
    if (record.member === null || record.member.status === false) {
      values = finalMenus.filter((item) => item.title !== T.EDIT_ROLE);
    } else {
      values = finalMenus.filter((item) => item.title !== T.ASSIGN_ROLE);
    }

    if (record.empStatus === T.STABLE || record.empStatus === T.YET_TO_JOIN) {
      return values.filter((item) => item.title !== T.REVOKE);
    } else {
      return values.filter((item) => ![T.DEACTIVATE, T.ASSIGN_ROLE].includes(item.title));
    }
  };

  return (
    <TableBody>
      {Children.toArray(
        records.map((record) => {
          // const isNewJoiner = get(record, "isNewJoiner", false);

          // const finalMenu = getMenu(record);
          // const userId = get(record, "id", "");
          // const userName = get(record, "userName", "");
          // const user_id = get(record, "id", "");
          // const userDepartment = get(record, "userDepartment", {});
          // const appraisalDueDate = get(record, "appraisalDueDate", null);
          const onBenchDuration = get(record, "projectModeDate", null);
          return (
            <TableRow sx={{ background: BACKGROUND.white }}>
              {Children.toArray(
                columns.map((column, index) => {
                  const colKey = get(column, "columnKey", "");

                  const { columnData } = getMemberColumnData(record, isXs, colKey, handleExport, user, true);

                  if (colKey === COL_KEYS.availabilitySince)
                    return (
                      <StyledTableCell>
                        <TitleTypography>{onBenchDuration}</TitleTypography>
                      </StyledTableCell>
                    );

                  return (
                    <StyledTableCell className={colKey === COL_KEYS.info && "sticky-col"}>
                      {columnData}
                    </StyledTableCell>
                  );
                }),
              )}
            </TableRow>
          );
        }),
      )}
      <AssignRole
        assign={assignRole}
        editAssign={assignEditRole}
        subRolesMapping={subRolesMapping}
        handleDialog={handleAssignDialog}
        handleCloseDialog={handleCloseDialog}
        refreshTable={refreshUserAction}
        userId={userId}
      />
      <AssignedCourseDialog
        selectedUserName={userName}
        isDialogOpen={assignedCourse}
        handleAssignedCourseDialog={handleAssignedCourseDialog}
        userId={userId}
      />

      <DeactivateUser
        deactivateMember={deactivateMember}
        handleDeactivateDialog={handleDeactivateDialog}
        userId={userId}
        records={records}
        refreshTable={refreshUserAction}
        // refreshTable={refreshMemberTable}
      />
      <RevokeMemberResignation
        revokeResignation={revokeResignation}
        handleRevokeResignationDialog={handleRevokeResignationDialog}
        userId={userId}
        refreshTable={refreshUserAction}
        // refreshTable={refreshMemberTable}
      />
      <DeleteUser
        deleteMember={deleteUser}
        handleDeleteUserDialog={handleDeleteUserDialog}
        userId={userId}
        refreshTable={refreshUserAction}
        // refreshTable={refreshMemberTable}
      />
    </TableBody>
  );
};

MISTableBody.propTypes = {
  records: PropTypes.array,
  refreshMemberTable: PropTypes.func,
};

export default memo(MISTableBody);
