import { useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";

import { useNavigate } from "react-router-dom";

import { Grid, Box, styled, Tooltip } from "@mui/material";

import CSV from "assets/CSV.png";

import T from "T";

import { GET_SIZE } from "utils/responsive";
import { get } from "lodash";
import { MISCurrentUser } from "utils/validations";
import { useUploadExcelMutation } from "api/members/uploadExcel";
import Constants from "Constants";
import SearchBar from "components/Members/SearchBar";
import Filters from "../Filters";

const { SERVER_URL } = Constants;
export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  searchInput = "",
  showActive = false,
  isFilterApplied = false,
  isFilterStoredEmpty = false,
  showCancelIcon = false,
  filters = {},
  projectManagers = {},
  workLocationList = {},
  skillList = {},
  departmentList = [],
  projectList = {},
  handleFilterSubmit = noop,
  handleFilterClose = noop,
  onhandleFilterChange = noop,
  handleExport = noop,
  handleKeyChange = noop,
  handleChange = noop,
}) => {
  const { user } = MISCurrentUser();
  const [uploadExcel] = useUploadExcelMutation();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    addView: false,
  });
  const { addView } = localState;
  const minExp = get(filters, "minExp", "");
  const maxExp = get(filters, "maxExp", "");

  const exportFilter = {
    availability: get(filters, "Availability", ""),
    departmentName: get(filters, "Department", []).map((dep) => dep.departmentName),
    empMode: get(filters, "Emp Mode", ""),
    empStatus: get(filters, "Status", ""),
    projectManagerName: get(filters, "Project Manager", []).map((pm) => pm.name),
    projectMode: get(filters, "Project Mode", ""),
    projectName: get(filters, "Project", []).map((pr) => pr.name),
    primarySkillName: get(filters, "Primary Skill", []).map((skill) => skill.skillName),
    secondarySkillName: get(filters, "Secondary Skill", []).map((skill) => skill.skillName),
    status: T.ACTIVE,
    workLocationName: get(filters, "Work Location", []).map((wl) => wl.workLocationName),
    totalExp: minExp && maxExp ? `${minExp}-${maxExp}` : "",
  };

  const { isLg } = GET_SIZE();

  return (
    <Grid
      container
      gap={2}
      alignItems="center"
      justifyContent={isLg ? "flex-start" : "center"}
      sx={{ mt: 1, p: 1, backgroundColor: "background.card" }}
    >
      <Grid item md={3} xs={12}>
        <SearchBar
          // records={searchTableData}
          searchInput={searchInput}
          // showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          // onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          // reset={reset}
          // onClickOutside={onClickOutside}
        />
      </Grid>
      <Grid item md={2} xs={12}>
        <Filters
          projectManagers={projectManagers}
          workLocationList={workLocationList}
          skillList={skillList}
          departmentList={departmentList}
          projectList={projectList}
          filters={filters}
          isFilterApplied={isFilterApplied}
          isFilterStoredEmpty={isFilterStoredEmpty}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
        />
      </Grid>

      <Grid item md={1} xs={12}>
        <Box display="flex" alignItems="center">
          <Tooltip title={T.CSV} placement="top">
            <StyledBox component="img" src={CSV} onClick={() => handleExport(T.CSV, exportFilter)} />
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  filtersList: PropTypes.object,
  searchedTableData: PropTypes.object,
  searchInput: PropTypes.string,
  showActive: PropTypes.bool,
  isFilterStoredEmpty: PropTypes.bool,
  selectedFilterId: PropTypes.string,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  isFilterApplied: PropTypes.bool,
  filters: PropTypes.object,
  projectManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  skillList: PropTypes.object,
  departmentList: PropTypes.object,
  projectList: PropTypes.object,
  handleFilterApply: PropTypes.func,
  handleFilterDelete: PropTypes.func,
  handleFilterSave: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleExport: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleClick: PropTypes.func,
  handleKeyChange: PropTypes.func,
  onChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
  handleConfigurator: PropTypes.func,
  onHandleFilterSelect: PropTypes.func,
};

export default TopBar;
