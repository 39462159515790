import T from "T";

const {
  PERSONAL_INFO,
  CONTACT_INFO,
  PRIMARY_PROJECT,
  SECONDARY_PROJECT,
  EXPERIENCE,
  TECHNOLOGY,
  AVAILABILITY,
  REPORTING_MANAGER,
  FUNCTIONAL_MANAGER,
  REMOVE_SHORTLIST,
  S_NO,
  BYT_MANAGER,
  AVAILABILITY_SINCE,
  CLIENT_PROJECT_MODE,
} = T;

export const COL_KEYS = {
  info: "personalInfo",
  contact: "contactInfo",
  primaryProject: "primaryProject",
  secondaryProject: "secondaryProject",
  experience: "experience",
  onBenchDuration: "availableSince",
  workLocation: "workLocation",
  technology: "technology",
  availability: "availability",
  availabilityDate: "availabilityDate",
  clientJira: "clientJira",
  internalJiraExemption: "internalJiraExemption",
  reportingManager: "reportingManager",
  functionalManager: "functionalManager",
  trainings: "trainings",
  comments: "comments",
  empMode: "empMode",
  cvUpload: "cvUpload",
  cvDownload: "cvDownload",
  cvUploaded: "cvUploaded",
  codeAssessment: "codeAssessment",
  scheduleInterview: "scheduleInterview",
  removeShortlist: "removeShortlist",
  clientName: "clientName",
  existingNew: "existingNew",
  startDate: "startDate",
  endDate: "endDate",
  interviewProcess: "interviewProcess",
  interviewPlatform: "interviewPlatform",
  jdid: "jdid",
  status: "status",
  reasonsForRejection: "reasonsForRejection",
  editInterviewTracker: "editInterviewTracker",
  cancelInterview: "cancelInterview",
  action: "action",
  defaultCv: "defaultCv",
  interviewSchedule: "interviewSchedule",
  serialnumber: "serialnumber",
  bytManager: "bytManager",
  clientProjectMode: "clientProjectMode",
  availabilitySince: "availabilitySince",
};

export const TABLE_COLUMNS = [
  {
    position: 0,
    label: REMOVE_SHORTLIST,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.removeShortlist,
  },
  {
    position: 1,
    label: S_NO,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.serialnumber,
  },
  {
    position: 2,
    label: PERSONAL_INFO,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.info,
  },
  {
    position: 3,
    label: CONTACT_INFO,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.contact,
  },
  {
    position: 4,
    label: AVAILABILITY,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.availability,
  },
  {
    position: 5,
    label: CLIENT_PROJECT_MODE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.clientProjectMode,
  },
  {
    position: 6,
    label: EXPERIENCE,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.experience,
  },
  {
    position: 7,
    label: TECHNOLOGY,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.technology,
  },
  {
    position: 8,
    label: PRIMARY_PROJECT,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.primaryProject,
  },
  {
    position: 9,
    label: SECONDARY_PROJECT,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.secondaryProject,
  },
  {
    position: 10,
    label: REPORTING_MANAGER,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.reportingManager,
  },
  {
    position: 11,
    label: FUNCTIONAL_MANAGER,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.functionalManager,
  },
  {
    position: 12,
    label: BYT_MANAGER,
    checked: true,
    locked: false,
    columnKey: COL_KEYS.bytManager,
  },
];

export const ON_BENCH_COLUMNS = TABLE_COLUMNS.filter(
  (column) =>
    column.columnKey === COL_KEYS.info ||
    column.columnKey === COL_KEYS.contact ||
    column.columnKey === COL_KEYS.primaryProject ||
    column.columnKey === COL_KEYS.secondaryProject ||
    column.columnKey === COL_KEYS.experience ||
    // column.columnKey === COL_KEYS.onBenchDuration ||
    // column.columnKey === COL_KEYS.clientProjectMode ||
    column.columnKey === COL_KEYS.workLocation ||
    column.columnKey === COL_KEYS.technology ||
    column.columnKey === COL_KEYS.availability ||
    column.columnKey === COL_KEYS.clientProjectMode ||
    // column.columnKey === COL_KEYS.clientJira ||
    // column.columnKey === COL_KEYS.internalJiraExemption ||
    column.columnKey === COL_KEYS.reportingManager ||
    column.columnKey === COL_KEYS.functionalManager,
  // column.columnKey === COL_KEYS.trainings ||
  // column.columnKey === COL_KEYS.comments,
  // ||
  // column.columnKey === COL_KEYS.empMode,
);
