/* eslint-disable */

export default class T {
  static ABSENT = "Absent";
  static PARTIALLY_ATTENDED = "Partially Attended";
  static ATTENDED = "Attended";
  static PLEASE_ENTER_YOUR_DETAILS = "Please Enter Your Details";
  static YOUR_NAME = "Your Name";
  static ENTER_YOUR_NAME_HERE = "Enter Your Name Here";
  static ENTER_YOUR_EMAIL_ID_HERE = "Enter Your Email Id Here";
  static ENTER_YOUR_STREAM_HERE = "Enter Your Stream Here";
  static COLLEGE_UNIVERSITY_NAME = "College/University Name";
  static INTERVIEW_TOOL = "Interview Tool";
  static STREAM = "Stream";
  static RESPONSE_SUBMISSION_STATEMENT = "Responses were successfully submitted";

  static DASHBOARD = "Dashboard";
  static REJECTED = "Rejected";
  static CONFIRM_REJECTED = "Confirm Rejected";
  static SUBMITTED = "Submitted";
  static COMPLETED = "Completed";
  static ANSWER = "Answer";
  static NEXT = "Next";
  static INSTRUCTIONS = "Instructions";
  static RE_ATTEMPT = "Re-Attempt";
  static ASSIGNMENT_STARTED = "Assignment Started";
  static TESTIMONIAL_ADDED_SUCCESSFULLY = "Testimonial Added Successfully";
  static ANSWERS_SUBMITTED_SUCCESSFULLY = "Answers Submitted Successfully";
  static CASESTUDY = "case-study";
  static TO_DO = "To do";
  static TESTIMONIAL = "Testimonial";
  static TESTIMONIAL_LINK = "Testimonial links";
  static TESTIMONIALS_VIEW = "Testimonials View";
  static ADD_TESTIMONIALS = "Add Testimonial";
  static LETS_START = "Let's Start!";
  static ADDED_ASSIGNMENT_SUCCESSFULLY = "Added Assignment Successfully";
  static GENERATED_CODING_QUESTIONS = "generated Coding Question";
  static ADD_CONSULTANT = "Add Consultant";
  static CASE_STUDY = "Case Study";
  static IN_MINUTES = "In Minutes";
  static ASSINMENTS_LIST = "Assignments List";
  static NEW_ASSIGNMENT = "New Assignment";
  static MEMBER_ASSIGNMENTS = "Member Assignments";
  static TECH_ASSIGNMENT = "Tech Assignment";
  static TECHNICAL_ASSIGNMENT_FOR = "Technical Assignment For";
  static PMKRA = "PM+KRA";
  static PROJECT_MANAGER = "Project Manager";
  static SEARCH_PROFILES = "Search Profiles";
  static TECHNICAL_ASSIGNMENT = "Technical Assignment";
  static ASSIGNMENT_DATE = "Assignment Date";
  static SUBMISSION_DATE = "Submission Date";
  static TIME_TAKEN = "Time Taken";
  static TOPIC = "Topic";
  static ASSIGNED = "Assigned";
  static BYT_MANAGER = "BYT Manager";
  static CASE_STUDY_PROJECT_MANAGER = "Project Manager";
  static CASE_STUDY_DUE_DATE = "Due Date";
  static LINK_ADDED_SUCCESSFULLY = "Link Added Successfully";
  static CASE_STUDY_LIST_VIEW = "Case Study List View";
  static CASE_STUDY_STATUS = "Status";
  static ADD_CASE_STUDY = "Add Case Study";
  static ADD_CASE_STUDY_LINK = "Add Case-Study Link";
  static CASE_STUDY_LINK = "Case-Study Link";
  static CASE_STUDY_UPLOAD = "Case-Study Upload";
  static CASE_STUDY_UPLOADED_SUCCESSFULLY = "Case Study uploaded successfully";
  static CASE_STUDY_ADDED_SUCCESSFULLY = "Case Study Added Successfully";
  static SUBMISSION_FILE_OR_TIME_TAKEN_MISSING = "Submission File or Time Taken Missing";
  static REJECT_CASE_STUDY_STATEMENT = "Please Fill The Reason";
  static CASE_STUDY_REJECTED = "Case-Study Rejected";
  static CASE_STUDY_ACCEPTED = "Case-Study Accepted";
  static EDIT_CASE_STUDY = "Edit Case Study";
  static CASE_STUDY_SUBMISSION_DATE = "Submission Date";
  static CASE_STUDY_ACTION = "Action";
  static FINISH = "Finish";
  static IS_PUBLISHED = "Is-Published";
  static MARK_AS_PUBLISHED = "Mark as Published";
  static CASE_STUDY_PUBLISHED = "Case-Study is Published";
  static REQUEST_NOT_FULFILLED = "Request not Fulfilled";
  static INVALID_URL_FORMAT = "Invalid url";
  static CASE_STUDY_PROJECT_START_DATE = "Project Start Date";
  static PRE_SALES_PROJECT_LIST_VIEW = "Pre Sales Project List View";
  static ERROR_PAGE = "Error Page";
  static SOMETHING_WENT_WRONG = "Something Went Wrong...";
  static TRAINING_COMMING_SOON = "Training Comming Soon...";
  static HELP_PAGE = "Help Page";
  static HOME_PAGE = "Home Page";
  static SETTINGS_PAGE = "Settings Page";
  static LOGIN = "Login";
  static LOGOUT = "Logout";
  static EMAIL = "Email";
  static EMAIL_ID = "Email Id";
  static PASSWORD = "Password";
  static REGION = "Region";
  static SHIFT = "Shift";
  static SORT = "Sort";
  static DISABLED = "Disabled";
  static REMEMBER_ME = "Remember Me?";
  static OOPS_I_FORGOT = "Oops! I Forgot";
  static FORGOT_PWD = "Forgot Password?";
  static JOB_DESCRIPTION = "Job Description";
  static COURSE_DESCRIPTION = "Course Description";
  static FORGOT_YOUR_PWD = "Forgot Password";
  static FREQUENCY_OF_JIRA = "Frequency of Jira";
  static JIRA_NAME = "Jira Name";
  static JIRA_ID = "Jira Id";
  static INSIDE_SALES = "Inside-Sales";
  static MOCK_INTERVIEW = "Mock Interview";
  static MOCK_ASSESSMENT = "Mock Assessment";
  static MOCK_ASSESSMENT_RESULT = "Mock Assessment Result";
  static ATTEMPTED_ON = "Attempted On";
  static RECORDINGS = "Recordings";
  static AI_ASSESSMENT = "AI Assessment";
  static SELF_PROSPECTING = "Self Prospecting";
  static EXISTING_CLIENT = "Existing Client";
  static OTHERS = "Others";
  static LOW = "Low";
  static MID = "Mid";
  static AT_PAR = "At Par";
  static REQUEST_TO_DELETE = "Request To Delete";
  static CONSOLIDATED_NC_LIST = "Consolidated NC List";
  static NC_DELETED_SUCCESSFULLY = "NC Deleted Successfully";
  static COURSE_REVOKED = "Course Revoked Successfully!";
  static NCS_DELETED_SUCCESSFULLY = "NCs Deleted Successfully";
  static DELETE_SELECTED_NCS = "Delete Selected NCs";
  static SHORTLIST_CANDIDATES = "Shortlist Candidate(s)";
  static SHORTLISTED_CANDIDATES = "Shortlisted Candidates";
  static PITCHED_CANDIDATES = "Pitched Candidates";
  static PITCHED_CANDIDATE = "Pitched Candidate";
  static DELETED_NC_LIST = "Deleted NC List";
  static CREATE_NEW_PROJECT = "Create New Project";
  static PRE_SALES_REQUIREMENT = "Pre Sales Requirement";
  static CREATE_NEW_PRE_SALES_REQUIREMENT = "Create New Pre Sales Requirement";
  static CREATE_NEW_TRAINING = "Create New Training";
  static ADD_NEW_CONSULTANT = "Add New Consultant?";
  static EDIT_PRE_SALES_REQUIREMENT = "Edit Pre Sales Requirement";
  static WORK_LOCATIONS = "Work Locations";
  static LOGGED_HRS = "Logged Hrs";
  static WORKING_DAYS = "Working Days";
  static DELETE_LOCATION = "Delete Location";
  static ACCOUNTING_INFO = "Accounting Info";
  static ACCOUNT_MANAGER = "Account Manager";
  static CHANGE_PWD = "Change Password";
  static CURRENT_PWD = "Current Password";
  static ENTER_NEW_PWD = "Enter New Password";
  static TOTAL_TIME_LOGGED = "Total Time Logged";
  static CONFIRM_PWD = "Confirm Password";
  static PROJECT_NAME = "Project Name";
  static PROJECTS = "Projects";
  static EMP_MODE = "Emp Mode";
  static EMP_STATUS = "Emp Status";
  static CV_UPLOAD = "Upload CV";
  static CV_DOWNLOAD = "Download CV";
  static REMOVE_SHORTLIST = "";
  static EDIT_ALL_PITCHED = "Edit Details";
  static CONFIRM_SUBMIT = "Are you sure you want to submit?";
  static CONFIRM_DELETE = "Are you sure you want to delete?";
  static DELETE_RECORDINGS = "Are you sure you want to delete the recordings?";
  static CANCEL_INTERVIEW = "Cancel interview";
  static REMOVE_SHORTLISTED = "Remove shortlisted ";
  static PITCH_CANDIDATES = "Pitch Candidate(s)";
  static JOB_DESCRIPTION = "Job description";
  static ACTION_BUTTONS = "Actions";
  static INTERVIEW_TRACKER = "Interview Tracker";
  static REPORT_CARD = "Report Card";
  static TECH_STACK = "Tech Stack";
  static HIRING_CRITERIA = "Hiring Criteria";
  static OPEN = "Open";
  static CLOSED = "Closed";
  static PARTIALLY_CLOSED = "Partially Closed";
  static LOST = "Lost";
  static WON = "Won";
  static ON_HOLD = "On Hold";
  static SIR = "S.I.R.";
  static SPECIAL_INCIDENT_REGISTER = "Special Incident Register";
  static REPORTED_ON = "Reported On";
  static REPORTED_BY = "Reported By";
  static CATEGORY = "Category";
  static NC_DELETE_REQUEST = "Requested to Delete";
  static NC_DELETED = "NC Deleted";
  static EMPLOYEE_MODE = "Employee Mode";
  static TIME_SHEET = "TIME SHEET";
  static POSITIVE = "+ve";
  static NEGATIVE = "-ve";
  static INHOUSE = "In-House";
  static CONSULTANT = "Consultant";
  static FEEDBACK = "Feedback";
  static UPLOADING_TOOL = "Uploading Tool";
  static BULK_MEMBER_UPLOAD = "Bulk Member Upload";
  static FEEDBACK_SUBMITTED_SUCCESSFULLY = "Feedback Submitted Successfully";
  static RESPONSE_SUBMITTED_SUCCESSFULLY = "Response submitted sucessfully";
  static ASSIGNMENT_SUBMITTED_SUCCESSFULLY = "Assignment submitted sucessfully";
  static SELECTED_MEMBERS_SHORTLISTED = "Selected Members Has Been Shorlisted Successfully";
  static RESOURCE_FILE_DOWNLOADED_SUCCESSFULLY = "Resource File Download Successfully";
  static ASSESSMENT_FILE_DOWNLOAD_SUCCESSFULLY = "Assessment File Download Successfully";
  static SELECTED_MEMBERS_SHORTLISTED = "Selected Members Has Been Shorlisted Successfully";
  static SHORTLISTED_MEMBERS_PITCHED = "Shortlisted Members Has Been Pitched Successfully";
  static JOB_DETAILS_FILE_DOWNLOADED_SUCCESSFULLY = "Job Details File Downloaded Successfully";
  static FILE_DOWNLOADED_SUCCESSFULLY = "File Downloaded Successfully";
  static PLEASE_FILL_ALL_REQUIRED_FEILDS = "Please fill all the Required Fields";
  static FREQUENCY_OF_JIRA = "Frequency of Jira";
  static DATE = "Date";
  static DATE_TIME = "Date & Time";
  static DAILY = "Daily";
  static WEEKLY = "Weekly";
  static REVOKE_RESIGNATION = "Revoke Resignation";
  static INCORRECT = "Incorrect";
  static TYPE_YOUR_EMAIL_HERE = "Type your email here";
  static TYPE_YOUR_PWD_HERE = "Type your password here";
  static MANAGEMENT_INFO_SYSTEM = "Management Information System";
  static NC_BASIS = "NC Basis";
  static ADD_NC = "Add NC";
  static NCS = "NCs";
  //trainingModal Status
  static YET_TO_START = "Yet To Start";
  static REVIEW_PENDING = "Review Pending";
  static IN_PROGRESS = "In Process";
  static PROGRESSING = "In Progress";
  static COMPLETE = "Complete";
  static REVIEW_COMPLETE = "Review Completed";
  static ATTENDED_REVIEW_PENDING = "Attended Review Pending";
  // Training List View
  static PENDING_DAYS = "Pending Days";
  static TRAINING_LIST_VIEW = "Training List View";
  static FILE = "File";
  static ADD_NEW_PROJECT = "Add New Project";
  static ADD_NEW_LOCATION = "Add New Location";
  static ADD_NEW_WORK_LOCATION = "Add New Work Location";
  static STARTED_SUCCESSFULLY = "Started Successfully";
  static COMPLETED_SUCCESSFULLY = "Completed Successfully ";
  static LOCATION_UPDATED_SUCCESSFULLY = "Location Updated Successfully";
  static LOCATION_ADDED_SUCCESSFULLY = "Location Added Successfully";
  static SUNDAY = "Sunday";
  static MONDAY = "Monday";
  static TUESDAY = "Tuesday";
  static WEDNESDAY = "Wednesday";
  static THURSDAY = "Thursday";
  static FRIDAY = "Friday";
  static SATURDAY = "Saturday";
  static AUSTRALIA = "Australia";
  static CANADA = "Canada";
  static INDIA = "India";
  static SINGAPORE = "Singapore";
  static USA = "USA";
  static NORTH_AMERICAN = "North American";
  static SOUTH_AMERICAN = "South American";
  static NORWEGIAN = "Norwegian";
  static EUROPEAN = "European";
  static APAC = "APAC";
  static BACK_TO = "Back To";
  static SUBMIT = "Submit";
  static UPDATE = "Update";
  static SUBMIT_ANYWAY = "Submit Anyway";
  static ADD_NEW_PROJECT_DETAILS = "Add New Project Details";
  static ADD_NEW_TECHNOLOGY = "Add New Technology";
  static NEW_TECHNOLOGY = "New Technology";
  static NEW_LOCATION = "New Location";
  static ADD_EDIT_PROJECT_DETAILS = "Add Edit Project Details";
  static EDIT_PROJECT_DETAILS = "Edit Project Details";
  static EDIT_TECHNOLOGY = "Edit Technology";
  static EDIT_LOCATION = "Edit Location";
  static TELL_US_WHY = "Tell us why?";
  static CANCEL = "Cancel";
  static DID_NOT_JOIN = "Didn't join";
  static REASON = "Reason";
  static ABSCONDED = "Absconded";
  static PERSONAL_DETAILS = "PERSONAL DETAILS";
  static PRE_SALES_REQUIREMENTS = "Pre Sales Requirements";
  static PROFESSIONAL_DETAILS = "PROFESSIONAL DETAILS";
  static WRITE_YOUR_COMMENTS = "Write your comments...";
  static SAVE_AND_CONTINUE = "Save & Continue";
  static NEW_PASSWORD_CONDITION = "Minimum 7 alphanumeric characters with at least 1 upper case letter.";
  static NO_SPECIAL_CHARACTERS_ALLOWED = "No special characters allowed.";
  static LOGOUT_SUCCESS = "Logged Out Successfully!!!";
  static BACK_TO_LOGIN = "Back to Login";
  static PAGE = "Page";
  static DELETED = "Deleted";
  static REPORTS = "Reports";
  static MEMBERS = "Members";
  static HRBP = "HRBP";
  static WORK_GROUP = "Work Group";
  static IS = "IS"; /// use for inside Sales
  static CORPORATE_TRAINER = "Corporate Trainer";
  static PROFILE = "Profile";
  static PROFILE_LINK = "Profile Link";
  static LINKED_IN_URL = "LinkedIn URL";
  static PROFILE_LINK_WORD_FORMAT = "Profile Link- Word Format";
  static PROFILE_LINK_PDF_FORMAT = "Profile Link- PDF Format ";
  static ADD_ROLE = "Add Role";
  static ROLES = "Roles";
  static MODULES = "Modules";
  static ROLE_AND_PERMISSIONS = "Role & Permissions";
  static ACTIVE = "Active";
  static INACTIVE = "Inactive";
  static ADD = "Add";
  static REMOVE = "Remove";
  static DELETE = "Delete";
  static RELIEVE = "Relieve";
  static LEAVE = "Leave";
  static PM_FILTER = "PM Filter";
  static PROCEED = "Proceed";
  static PERMISSIONS = "Permissions";
  static DESCRIPTION = "Description";
  static TOPICS = "Topics";
  static CONFIRM = "Confirm";
  static DELETE_CONFIRMATION = "Do you want to delete";
  static NAME_OF_THE_LEAD = "Name of the Lead";
  static REQUIREMENT_FOR = "Requirement For";
  static REQUIREMENT_TYPE = "Requirement Type";
  static PREFERRED_TECHNOLOGY = "Preferred Technology";
  static OS_MANAGER = "OS Manager";
  static COMPANY_NAME = "Company Name";
  static COMPANY_WEBSITE_LINK = "Company's Website Link (If available)";
  static SOURCE = "Source";
  static IF_EXISTING_CLIENT = "If Existing Client";
  static CLIENTS_PREVIOUS_PROJECT = "Client's Previous Project";
  static CLIENT_REGION = "Client Region";
  static FULL_NAME = "Full Name";
  static EMP_CODE = "Employee Code";
  static EMP_NAME = "Employee Name";
  static SELECT_ALL = "Select All";
  static PHONE = "Phone";
  static NUMBER = "Number";
  static DEPARTMENT = "Department";
  static REPORTING_MANAGER = "Reporting Manager";
  static FUNCTIONAL_MANAGER = "Functional Manager";
  static JOINING_DATE_IN_NTZ = "Joining Date in Netsmartz";
  static NETSMARTZ_ESTABLISHMENT_DATE = "09/29/1999";
  static CAREER_START_DATE = "Career Start Date";
  static TOTAL_EXP_AS_ON_DATE = "Total Experience as on Date";
  static TOTAL_EXP = "Total Experience";
  static WORK_LOCATION = "Work Location";
  static WORK_MODE = "Work Mode";
  static EMPLOYMENT_MODE = "Employment Mode";
  static WORK_MODE_EXCEPTION_REASON = "Work Mode Exception Reason";
  static TOTAL_EXPERIENCE = "Total Experience";
  static HR = "HR";
  static PMO = "PMO";
  static PM = "PM";
  static VP = "VP";
  static STABLE = "Stable";
  static YET_TO_JOIN = "Yet to join";
  static RESIGNED = "Resigned";
  static RELIEVED = "Relieved";
  static SEPARATED = "Separated";
  static NON_COMPLIANCE = "Non-Compliance";
  static NON_WORKING_DAYS = "Non-Working Days";
  static ROLE = "Role";
  static MEMBERS_LIST = "MEMBERS LIST";
  static RECOMMENDED_MEMBERS_LIST = "Recommended Members";
  static RECOMMENDATION_REPORT = "Recommendation Report";
  static SHORTLISTED_MEMBERS_LIST = "Shortlisted Members";
  static PITCHED_MEMBERS_LIST = "Pitched Members";
  static CLIENT_NAME = "Client Name";
  static EXISTING_NEW = "Existing/New";
  static INTERVIEW_PROCESS = "Interview Process";
  static INTERVIEW_PLATFORM = "Interview Platform";
  static PLATFORM = "Platform";
  static REASONS_FOR_REJECTION = "Rejection Reason";
  static CODE_ASSESSMENT = "Code Assessment";
  static INTERVIEW_READINESS_CHECKLIST = "Interview Readiness Checklist";
  static CANDIDATE = "Candidate";
  static SUCCESS = "Success!";
  static RESPONSE_SUBMITTED = " Response submitted.";
  // static RESPONSE_SUBMITTED = "Your response has been successfully submitted.";
  // static RECIEVE_INTERVIEW_DETAILS = "You will receive an email with interview details shortly.";
  static RECIEVE_INTERVIEW_DETAILS = " Interview details sent to your email.";
  static CODE_ASSESSMENT_SUBMISSION = " Code Assessment Submission";
  static INTERVIEW_COORDINATOR = "Interview coordinator";
  static BROWSE_FILES = "Browse Files";
  static SUBMIT_ASSESSMENT = "Submit Assessment";
  static CONFIRM_SUBMIT_ASSESSMENT = "Are you sure you want to submit the checklist?";
  static VIEW_CODE_ASSESMENT = "Code Assessment Details";
  static SUBMISSION_DEADLINE = "Submission Deadline";
  static SUBMISSION_DATE = "Submission Date";
  static REVIEW_DATE = "Review Date";
  static INTERVIEW_STATUS = "Interview Status";
  static INTERVIEW_SCHEDULE = "Interview Schedule";

  static DRAG_DROP_FILES = "Drag and drop your files here";
  static FILES_SHOWN_HERE = "Your files will be shown here";
  static CHECKLIST_CANDIDATE_WARNING = "Candidate must fill out this checklist before the interview.";
  static INTERVIEW_CHECKLIST = "Interview Checklist";
  static VIEW_CLIENT = "View Client";
  static SCHEDULE_INTERVIEW = "Schedule Interview - Round 1";
  static SCHEDULE_INTERVIEW_2 = "Schedule Interview - Round 2";
  static SCHEDULE_MOCK_INTERVIEW = "Schedule Mock Interview";
  static RESCHEDULE_INTERVIEW = "Reschedule Interview - Round 1";
  static RESCHEDULE_INTERVIEW_2 = "Reschedule Interview - Round 2";
  static PRE_INTERVIEW_NOTIFICATION = "Pre-Interview Notification";
  static CV_UPLOADED = "CV uploaded";
  static DEFAULT_CV = "Default CV";
  static INTERVIEW_SCHEDULED = "Interview Scheduled";
  static DASHBOARD_VIEW = "DASHBOARD VIEW";
  static SEARCH_PLACEHOLDER = "Search";
  static FILTER = "Filter";
  static FILTERS = "Filters";
  static TECHNOLOGY = "Technology";
  static PORTFOLIO = "User Portfolio";
  static USER_PORTFOLIO = "Enter Your Portfolio";
  static USER_PORTFOLIO_LINK = "Portfolio Link";
  static TECHNICAL = "Technical";
  static OFFLINE_TRAINING = "Offline Training";
  static MARK_ATTENDANCE = "Mark Attendance";
  static TECHNICAL_HISTORY = "Technical History";
  static TECHNOLOGY_NAME = "Technology Name";
  static LOCATION_NAME = "Location Name";
  static DELETE_TECHNOLOGY = "Delete Technology";
  static STATUS = "Status";
  static F = "Project Manager";
  static MANAGER = "Manager";
  static AVAILABILITY = "Availability";
  static EXPERIENCE = "Experience";
  static REPORTING_MANAGER = "Reporting Manager";
  static SAVED_VIEWS = "Saved Views";
  static CANCEL = "Cancel";
  static APPLY = "Apply";
  static SAVE = "Save";
  static CREATE = "Create";
  static ADD_MEMBER = "Add Member";
  static ADD_MEMBERS = "Add Members";
  static EDIT_MEMBER = "Edit Member";
  static PERSONAL_INFO = "Personal Info";
  static CONTACT_INFO = "Contact Info";
  static CLIENT_JIRA = "Client Jira";
  static JIRA_NOT_FILLED = "Jira Not Filled";
  static COMMENTS_MISSING = "Comments Missing";
  static SVN_CHECKIN = "SVN Check-In";
  static INTERNAL_JIRA_EXEMPTION = "Internal Jira Exemption";
  static FUNCTIONAL_MANAGER = "Functional Manager";
  static PROJECT_MODE = "Project Mode";
  static CLIENT_PROJECT_MODE = "Client Project Mode";
  static COMMENTS = "Comments";
  static COMMENT = "Comment";
  static JAN = "Jan";
  static FEB = "Feb";
  static MAR = "Mar";
  static APR = "Apr";
  static MAY = "May";
  static JUN = "Jun";
  static JUL = "Jul";
  static AUG = "Aug";
  static SEP = "Sep";
  static OCT = "Oct";
  static NOV = "Nov";
  static DEC = "Dec";
  static SVN = "SVN";
  static JIRA = "Jira";
  static BEGINNER = "Beginner";
  static INTERMEDIATE = "Intermediate";
  static EXPERT = "Expert";
  static PRIMARY_PROJECT = "Primary Project";
  static SECONDARY_PROJECT = "Secondary Project";
  static TRAINING = "Training";
  static ASSESSMENT_LINK = "Assessment Link";
  static VIEW = "View";
  static EDIT = "Edit";
  static EDIT_INFO = "Edit Info";
  static DEACTIVATE = "Deactivate";
  static ACTIVATE = "Activate";
  static SWOT_ANALYSIS = "SWOT Analysis";
  static SWOT_ACTIVITY_LOG = "SWOT Activity Log";
  static ACTIVITY_LOG = "Activity Log";
  static USER = "User";
  static TYPE = "Type";
  static SAVE_VIEW = "Save View";
  static VIEW_NAME = "View Name";
  static SUPER_ADMIN = "Super Admin";
  static YES = "Yes";
  static NO = "No";
  static CAREER_START_DATE = "Career Start Date";
  static TOTAL_EXP_AS_ON_DATE = "Total Exp as on Date";
  static JOINING_DATE_IN_NTZ = "Joining Date In Netsmartz";
  static YEARS = "Years";
  static RESIGNATION_DATE = "Resignation date";
  static RELIEVING_DATE = "Relieving date";
  static PRIMARY_SKILL = "Primary Skill";
  static PRIMARY_SKILLS = "Primary Skills";
  static SECONDARY_SKILL = "Secondary Skill";
  static SECONDARY_SKILLS = "Secondary Skills";
  static SECONDARY_SKILL_SET_TRAINING = "Secondary Skill Set Training";
  static PM = "PM";
  static TO = "To";
  static FROM = "From";
  static COURSE_START_DATE = "Course Start Date";
  static COURSE_END_DATE = "Course End Date";
  static START_DATE = "Start Date";
  static END_DATE = "End Date";
  static DUE_DATE = "Due Date";
  static ASSIGNED_ON = "Assigned On";
  static DUE_ON = "Due On";
  static SALES_MANAGER = "Sales Manager";
  static DYNAMICS_TOWER_HEAD = "Dynamics Tower Head";
  static DYNAMICS_VP = "Dynamics VP";
  static TRAINEE = "Trainee";
  static LOGGED_IN_SUCCESSFULLY = "Logged In Successfully";
  static EDIT_ROLE = "Edit Role";
  static FOOTER_TEXT = "Powered by BYT strategy group";
  static ASSIGN = "Assign";
  static DOWNLOAD = "Download";
  static DOWNLOAD_FILE = "Download file";
  static ASSIGN_ROLE = "Assign Role";
  static ASSIGN_MOCK_INTERVIEW = "Assign Mock Interview";
  static REMOVE_ROLE = "Remove Role";
  static EDIT_ROLE = "Edit Role";
  static ROLE_ASSIGNED_SUCCESSFULLY = "Role Assigned Successfully";
  static USER_DELETE_SUCCESSFULL = "User Deleted Successfully";
  static USER_DEACTIVATED_SUCCESSFULLY = "User Deactivated Successfully";
  static RESIGN_REVOKE_SUCCESSFULL = "Resignation Revoked Successfully";
  static CHANDIGARH = "Chandigarh";
  static MOHALI = "Mohali";
  static NOIDA = "Noida";
  static GURUGRAM = "Gurugram";
  static WFH = "WFH";
  static WFO = "WFO";
  static BASIC_DETAILS = "BASIC DETAILS";
  static WORK_DETAILS = "WORK DETAILS";
  static PROJECT_DETAILS = "Project Details";
  static EXIT_DETAILS = "EXIT DETAILS";
  static CONSULTANT_MEMBER = "Consultant Member";
  static INFORMED_DATE = "Informed Date";
  static SELECTED = "Selected";
  // New Course Add
  static OK = "OK";
  static ATTENDANCE = "Attendance";
  static ABSENT = "Absent";
  static PARTIALLY_ATTENDED = "Partially Attended";
  static ATTENDED = "Attended";
  static ADD_NEW_COURSE = "Add New Course";
  static COURSE_NAME = "Course Name";
  static TRAINING_NAME = "Training Name";
  static COURSE_TYPE = "Course Type";
  static SUB_COURSE_NAME = "Sub Course Name";
  static ASSESSMENT = "Assessment";
  static TRAINING_DURATION = "Training Duration";
  static PRACTICAL_DURATION = "Practical Duration";
  static UPLOAD_ASSESSMENT = "Upload Assessment";
  static COURSE_LINKS = "Course Links";
  static MEETING_LINK = "Meeting Link";
  static SUB_COURSE_LINKS = "Course Links";
  static UPLOAD_COURSE = "Upload Course";
  static CONSULTANT_MEMBER = "Consultant Member";
  static INFORMED_DATE = "Informed Date";
  static PLEASE_ADD_FEEDBACK = "Please Add Feedback";
  static NO_TRAINING_AVAILABLE = "No Training Available";
  static VIEW_DETAILS = "View Details";
  static ADD_DETAILS = "Add details";
  static EDIT_DETAILS = "Edit details";
  static BYT_LEAD = "BYT Lead";
  static SELECT = "Select";
  static SELECT_OPTION = "Select Option";
  static SELECT_TYPE_OF_FEEDBACK = "Select type of feedback";

  static BYT = "BYT";
  static DEVEOPS = "DevOps";
  static FIXED = "Fixed";
  static ON_BENCH = "On Bench";
  static OPERATIONS = "Operations";
  static PRODUCT = "Product";
  static T_N_M = "T & M";
  static TRAINING = "Training";
  static NAME = "Name";
  static USERNAME = "Username";
  static TRAINING_TO_BE_ASSIGNED = "Training to be Assigned";
  static DETAILS = "Details";
  static RATING = "Rating";
  static COMPLETE_REVIEW = "Complete Review";
  static PROJECT = "Project";
  static ALLOCATION = "Allocation";
  static AVAILABLE_HOURS = "Available Hours";
  static DESIGNATION = "Designation";
  static AVAILABILITY_DATE = "Availability Date";
  static TENURE_WITH_NETSMARTZ = "Tenure with Netsmartz";
  static SKILL = "Skill";
  static CREATE_NON_COMPLIANCE = "Create Non-Compliance";
  static USER_CREATED_SUCCESSFULLY = "User Created Successfully!!!";
  static Feedback_CREATED_SUCCESSFULLY = "Feedback Created  Successfully!!! ";
  static USER_UPDATED_SUCCESSFULLY = "User Updated Successfully!!!";
  static REQUIRED_FIELDS_EMPTY = "Please Add Required Fields!!!";
  static EXPERIENCE_OR_ASSIGNED_DURATION_CAN_NOT_BE_NEGATIVE = "Experience or Assigned Duration Can't Be Negative!";
  static TIME_TAKEN_CAN_NOT_BE_NEGATIVE = "Time Taken Can Not Be Negative!";
  static VALID_COURSE_DURATION = "Course Duration should be Number";
  static VALID_PRACTICAL_DURATION = "Practical Duration Should be Number";
  static VALID_URL_LINK = "Enter valid Url";
  static POSTS_MORE_THAN_9 = "Number of Posts can not be more than 9!!!";
  static COURSE_DURATION_MORE_THAN_365 = "Course duration can't be more than 365!!!"; // course duration
  static ROLE_CREATED_SUCCESSFULLY = "Role Created Successfully!!!";
  static SUBROLE_CREATED_SUCCESSFULLY = "SubRole Created Successfully!!!";
  static COPY = "Copy";
  static DELETED = "Deleted";
  static NO_RECORDS = "No Records Found!";
  static NO_RECORDS_MESSAGE = "There doesn't seem to be any data for this right now.";
  static ROLE_DELETED_SUCCESSFULLY = "Role Deleted Successfully!!!";
  static COPIED = "Copied";
  static PARTIAL = "Partial";
  static FULL = "Full";
  static NO = "No";
  static RESET = "Reset";
  static HYBRID = "Hybrid";
  static SHADOW = "Shadow";
  static SHADOW_TO = "Shadow To";
  static MIN = "Min";
  static MAX = "Max";
  static NO_FILTER_SELECTED = "No Filter Selected!!!";
  static PLEASE_ADD_FILTER_NAME = "Please Add Filter Name!!!";
  static FILTER_ADDED_SUCCESSFULLY = "Filter Added Successfully!!!";
  static FILTER_DELETED_SUCCESSFULLY = "Filter Deleted Successfully!!!";
  static FILTER_APPLIED_SUCCESSFULLY = "Filter Applied Successfully!!!";
  static NO_FILTERS_SAVED = "No Filters Saved";
  static USER_DELETE_SUCCESSFULL = "User Deleted Successfully";
  static USER_DEACTIVATED_SUCCESSFULLY = "User Deactivated Successfully";
  static RESIGN_REVOKE_SUCCESSFULL = "Resignation Revoked Successfully";
  static REASON = "Reason";
  static TELL_US_WHY = "Tell us why?";
  static USER = "User";
  static DID_NOT_JOIN = "Didn't join";
  static ABSCONDED = "Absconded";
  static REVOKE = "Revoke";
  static FILE_UPLOADED_SUCCESSFULLY = "File Uploaded Successfully";
  static ACCOUNTING_DOWNLOAD_REQUIREMENT_STATEMENT = "Please Select All the required filters to proceed download";
  static JIRA_CHANGE_STATEMENT = "You are about to change the Jira Name";
  static RESIGNATION_REQUEST_STATEMENT = "Are you sure you want to revoke this resignation request?";
  static DELETE_PERMISSION_STATEMENT = "Are you sure you want to delete this User?";
  static FORM_CANCEL_STATEMENT = "You will loose all the changes if cancelled!";
  static ROLE_REMOVED_STATEMENT = "The assigned role has been removed";
  static MOCK_INTERVIEW_ASSIGNED_SUCCESSFULLY = "Mock Interview Assigned Successfully";
  static TECHNICAL_INTERVIEW_ASSIGNED_SUCCESSFULLY = "Technical Interview Assigned Successfully";
  static GROUPS = "Groups";

  static ASSESSMENT_SUBMISSION_RESPONSE_STATEMENT =
    "Your solution is submitted successfully, We will show you the results after review in some time";
  static RESIGNATION_REQUEST_STATEMENT = "Are you sure you want to revoke this resignation request?";
  static DELETE_PERMISSION_STATEMENT = "Are you sure you want to delete this User?";
  static ROLE_REMOVED_STATEMENT = "The assigned role has been removed";
  static DELETE_PROJECT_STATEMENT = "Are you sure you want to delete this Project?";
  static DELETE_NC_STATEMENT = "Are you sure you want to delete this NC?";
  static DELETE_ALL_NC_STATEMENT = "Are you sure you want to delete these NCs?";
  static DELETE_TECHNOLOGY_STATEMENT = "Are you sure you want to delete this Technology?";
  static DELETE_LOCATION_STATEMENT = "Are you sure you want to delete this Location?";
  static PWD_SHARED_ON_EMAIL = "Password has been shared on your registered Email id. Please check!";
  static PWD_CHANGED_SUCCESSFULLY = "Password Changed Successfully";
  static REMEMBER_TO_CHANGE_MEMBER_DETAILS = "Did you remember to set the Member's Project Mode in the previous tab?";

  static UPLOAD_XLS = "Upload XLS";
  static UPLOAD_JD = "Upload JD";
  static UPLOAD_FILE = "Upload File";
  static UPLOAD_ASSESSMENT_FILE = "Upload Assessment File";
  static XLS = "XLS";
  static XL = "XL";
  static CSV = "CSV";
  static PDF = "PDF";
  static DOC = "DOC";
  static PPT = "PPT";
  static TXT = "TXT";
  static SAVE_FILTER = "Save Filter";
  static CONFIGURATOR = "Configurator";
  static IMMEDIATE = "Immediate";
  static NC_HISTORY = "NC History";
  static START_MONTH = "Start Month";
  static END_MONTH = "End Month";
  static NC_HISTORY = "NC History";
  static NC_DATE = "Date Of NC";
  static NC_LIST_VIEW = "NC LIST VIEW";
  static PROJECT_HISTORY = "Project History";
  static INTERVIEW_HISTORY = "Interview History";
  static NA = "NA";

  static S_NO = "S.No.";
  static TYPE_OF_NC = "Type of NC";
  static PROPOSAL_NAME = "Proposal Name";
  static ASSIGNED_TO = "Assigned To";
  static ASSIGN_TO = "Assign To";
  static BUDGET = "Budget";
  static JOB_TITLE = "Job Title";
  static DETAILED_JD = "Detailed Job Description";
  static EXPERIENCE_REQUIRED = "Experience Required";
  static JOB_OPENINGS = "Job Openings";
  static COMPANY_INFO = "Company Info";
  static LEAD_INFO = "Lead Info";
  static DAYS_IN_OPERATION = "Days In Ops.";
  static DAYS_IN_SALES = "Days In Sales";
  static NO_OF_POSITIONS = "Positions";
  static OS_MANAGER = "OS Manager";
  static REQUIREMENTS = "Requirements";
  static CREATED_BY = "Created By";
  static STRENGTHS = "Strengths";
  static WEAKNESSES = "Weaknesses";
  static OPPORTUNITIES = "Opportunities";
  static THREATS = "Threats";
  static SWOT_ANALYSIS_SUBMITTED_SUCCESSFULLY = "Swot Analysis Submitted Successfully";
  static SWOT_ANALYSIS_UPDATED_SUCCESSFULLY = "Swot Analysis Updated Successfully";
  static SWOT_ANALYSIS_DELETED_SUCCESSFULLY = "Swot Analysis Deleted Successfully";
  static ADD_SWOT_ANALYSIS = "Add SWOT";
  static EDIT_SWOT = "Edit Swot Analysis";
  static SWOT_ANALYSIS_DOWNLOADED_SUCCESSFULLY = "Swot Analysis downloaded successfully.";
  static PRIMARY_SKILL_REQUIRED = "Primary Skill Required";
  static SECONDARY_SKILL_REQUIRED = "Secondary Skill Required";
  static EXPERIENCE_CLIENT_IS_LOOKING_FOR = "Experience Client Is Looking For (In Years)";
  static OPENINGS_CLIENT_IS_LOOKING_FOR = "Openings Client Is Looking For";
  static HAS_THE_LEAD_EVER_BEEN_IN_CONTACT_WITH_NTZ_BEFORE = "Has The Lead Ever Been In Contact With NTZ Before?";
  static SELECTION_PROCESS_DESIRED_BY_CLIENT = "Selection Process Desired By Client";
  static FOR_BYT_TECHNOLOGY_STACK_JD_PROVIDED_BY_LEAD = "For BYT- Technology Stack/ JD Provided By Lead";
  static GOOD_TO_HAVE_SKILLS = "Good To Have Skills";
  static GOOD_TO_HAVE = "Good To Have";
  static MUST_HAVE_SKILLS = "Must Have Skills";
  static MUST_HAVE = "Must Have";
  static HAS_TRIAL_PERIOD_BEEN_OFFERED = "Has Trial Period Been Offered?";
  static PREREQUISITES = "Prerequisites";
  static JD_STATUS = "JD Status";
  static DETAILED_JOB_DESCRIPTION = "Detailed Job Description";
  static SUB_COURSE_DESCRIPTION = "Sub Course Description"; //training course
  static PREREQUISITES_MENTIONED_BY_THE_CLIENT = "Prerequisites Mentioned By The Client";
  static SPECIFIC_SHIFT_TIMINGS_MENTIONED_BY_THE_CLIENT = "Specific Shift Timings Mentioned By The Client";
  static PRESS_ENTER_TO_ADD_LINK = "(Press Enter to add link)";
  static COMMENTS_IF_ANY = "Comments(If Any)";
  static REQUIREMENT_STATUS = "Requirement Status";
  static JOB_STATUS = "Job Status";
  static RECOMMENDATIONS = "Recommendations";
  static DOWNLOAD_JD = "Download JD";
  static DELETE_PROJECT = "Delete Project";
  static DATES = "Dates";
  static LEAD_DATE = "Lead Date";
  static NC_MONTH = "NC Month";
  static NC_COUNT = "NC Count";
  static DELETED_NC_COUNT = "Deleted NC Count";
  static NC_TYPE = "NC Type";
  static DATE_OF_NC = "Date of NC";
  static DELETE_NC = "Delete NC";
  static NC_STATUS = "NC Status";
  static DELETE_PROJECT_INFO = "Delete Project Info";
  static NC_COMING_SOON = "NC Records Coming Soon";
  static ATTACH_RESUME = "Attach Resume";
  //used in Training Page
  static START = "Start";
  static EDIT_COURSE = "Edit Course";
  static REVOKE_COURSE = "Revoke";
  static ASSIGNED_BY = "Assigned By";
  static ASSIGN_COURSE = "Assign Course";
  static ASSIGN_L_D = "Assign L & D";
  static ADD_ASSIGNMENT = "Add Assignment";
  static ASSIGN_TRAINING = "Assign Training";
  static ASSIGN_GROUP_TRAINING = "Assign Group Training";
  static COURSE = "Course";
  static TOPIC = "Topic";
  static REJECTION_COMMENT = "Rejection Comment";
  static COURSE_ASSIGNED = "Course Assigned";
  static SUB_COURSE_NAME = "Sub Course Name";
  static COURSE_DESCRIPTION = "Course Description";
  static TRAINING_DURATION = "Training Duration";
  static COMPLETION_DATE = "Completion Date";
  static SUB_COURSE_COUNT = "Sub Course Count";
  static SUB_COURSES = "Sub Courses";
  static SUB_COURSE = "Sub Course";
  static COURSE_CREATED_BY = "Course Created By";
  static RESOURCE_LINKS = "Resource Links";
  static RESOURCE_FILE = "Resource File";
  static IN_HRS = "(In Hrs)";
  static HRS = "Hrs";
  static ASSIGNMENT_REVIEW = "Assignment Review";
  static REVIEWER_NAME = "Reviewer Name";
  static ASSIGNMENT_FILE = "Assignment File";
  static COURSE_COMMENT = "Comment";
  static COMPLETION_FILE = "Completion File";
  static COMPLETION_LINK = "Completion Link";
  static COMPLETION_TYPE = "Completion Type";
  static APPROVE_CERTIFICATE = "Approve Certificate";
  static TRAINING_HISTORY = "Training History";

  static APPRAISAL_LEARNING_PATH = "Assign Appraisal Linked Learning Path";
  static NEW_JOINER_LEARNING_PATH = "Assign New Joiner Learning Path";

  // static MAIN_COURSE = "Main Course";
  static DURATION = "Duration";
  static DURATION_IN_MINUTES = "Duration (In Minutes)";
  static CREATED_FROM = "Created From";
  static CREATED_TO = "Created To";
  static CREATED_AT = "Created At";
  static UPDATED_AT = "Updated On";
  static COURSE_DETAIL = "Course Detail";
  static ASSIGNMENT_DETAILS = "Assignment Details";
  static TRAINING_DETAIL = "Training Detail";
  static LINK = "Link";
  static OVER = "Over";
  static BOTH = "Both";
  static ASSIGN_DATE = "Assign Date";
  static COMPLETION_DATE = "Completion Date";
  static CERTIFICATE_GENERATED_SUCCESSFULLY = "Certificated Generated Successfully and Email has been sent to User";
  static MAIL_GENERATED_SUCCESSFULLY = "Mail Generated Successfully and Email has been sent to User";
  // used in Assign_Training Page
  static MEMBER_INFO = "Member Info";
  static DAYS_ELLAPSED = "Days Ellapsed";
  static REVIEW = "Review";
  static ATTEMPT_NO = "Attempt No";
  static RESET_PWD_MSG = "Please enter your registered email address to reset your password!";
  static LOGIN_INFO_1 = "An internal Netsmartz product powered by the BYT team that will empower us to manage our team better";
  static LOGIN_INFO_2 = "An internal Netsmartz product powered by the BYT team that will empower us to manage our team better";
  static FORGOT_PWD_INFO_1 = "Manage all your team members, their allocations and non-compliances from a single portal.";
  static FORGOT_PWD_INFO_2 = "Manage all your team members, their allocations and non-compliances from a single portal.";
  static TOGGLE_TO_SHOW_ACTIVE_MEMBERS = "Toggle to Show Active Members";
  static TOGGLE_TO_SHOW_INACTIVE_MEMBERS = "Toggle to Show Inactive Members";

  static CUSTOM = "Custom";

  static ONLY_NUMBERS_ALLOWED = "Only Numbers Allowed";
  static ONLY_POSITIVE_NUMBERS_ALLOWED = "Only Positive Numbers Allowed";

  static FILTER_NAME_ALREADY_EXISTS = "Filter Name Already Exists";

  static MASTER_SETTINGS = "Master Settings";
  static PRE_SALES = "Pre-Sales";
  static ACCOUNTING = "Accounting";
  static ACCOUNTANT = "Accountant";
  static PROJECT_ADDED_SUCCESSFULLY = "Project Added Successfully!";
  static REQUIREMENT_ADDED_SUCCESSFULLY = "Requirement Added Successfully!";
  static COURSE_ADDED_SUCCESSFULLY = "Course Added Successfully!";
  static COURSE_ASSIGNED_SUCCESSFULLY = "Course Assigned Successfully!";
  static COURSE_REVIEWED_SUCCESSFULLY = "Course Reviewed Successfully";
  static COURSE_UPDATE_SUCCESSFULLY = "Course Updated Successfully!";
  static PROJECT_UPDATED_SUCCESSFULLY = "Project Updated Successfully!";
  static REQUIREMENT_UPDATED_SUCCESSFULLY = "Requirement Updated Successfully!";
  static SKILL_UPDATED_SUCCESSFULLY = "Skill Updated Successfully!";
  static SKILL_ADDED_SUCCESSFULLY = "Skill Added Successfully!";

  static EXPIRING_PROJECTS = "Number of expiring projects this month";
  static RAMPED_DOWN_MEMBERS = "Number of members ramped down this month";
  static NEW_PROJECTS_NUMBERS = "Number of new projects this month";
  static ON_BENCH_NUMBERS = "Number of on bench members";
  static MEMBERS_JOINING_THIS_MONTH = "Number of members joining this month";
  static MEMBERS_RELIEVING = "Number of members getting relieved this month";

  static TEAM_DIVISION_TECH_MSG = "Team division based on Tech Stacks";
  static TEAM_DIVISION_MODE_MSG = "Team division based on Work Modes";
  static EXPIRING_PROJECTS_THIS_MONTH = "Expiring projects this month";
  static NEW_PROJECTS_THIS_MONTH = "New projects this month";
  static RAMPED_DOWN_MEMBERS_BASED_ON_STACK = "Members ramped down this month based on Tech Stack";
  static NEW_JOINERS_MSG = "New joiners this month based on Tech Stack";
  static ON_BENCH_MEMBERS_PER_TECH_STACK = "On bench members per tech stack";
  static ON_BENCH_MEMBERS_PER_MANAGER = "On bench members per manager";
  static MEMBERS_GETTING_RELIEVED_THIS_MONTH = "Members getting relieved this month";
  static NC_PER_MANAGER = "NCs per manager";
  static EMAIL_TO_PARTNER = "Email To Partner";
  static TESTIMONIAL_TEXT_OR_LINK = "Testimonial Text Or Link";
  static TESTIMONIAL_LINK = "Testimonial Link";
  static UPLOAD = "Upload";
  static UNSHORTLISTED_SUCCESSFULLY = "Unshortlisted Successfully";
  static PITCHED_SUCCESSFULLY = "Candidate is pitched for the project";
  static CV_UPLOADED_SUCCESSFULLY = "CV uploaded successfully";
  static BAD_REQUEST = "Bad Request. Please check your request and try again.";

  // FEEDBACK FIELDS
  static FEEDBACK_CREATED_BY = "Posted By";
  static TECHNICAL_FEEDBACK = "Technical Feedback";
  static CLIENT_FEEDBACK = "Client Feedback";
  static COMMUNICATION_SKILLS = "Communication Skills";
  static AVAILABILITY_FEEDBACK = "Availability";
  static TEAM_PLAYER = "Team Player";
  static FEEDBACK_TRAINING = "Training ";
  static TAKING_INITIATIVES = "Taking Initiative";
  static EDP_CONFORMATION = "Was the person on EDP ?";
  static EDP = "EDP";
  static PROCESS_COMPLIANCE = "Process compliance";
  static COMMENTS_FEEDBACK = "Comments";
  static REMINDER_BUTTON = "Send Reminder";
  static COMPLETED = "Completed";
  static REMINDER_SENT = "Reminder has been sent";
  static DISCIPLINE = "Discipline";
  static POST_FEEDBACK = "Post Feedback";
  static FEEDBACK_CREATED_ON = "Created On";
  static SUBMIT_FEEDBACK = "Submit Feedback";
  static EXISTING = "Existing";
  static NEW = "New";
  static ADD_FEEDBACK = "Add Feedback";
  static CREATE_REQUIREMENT = "Create Requirement";
  static VIEW_MEMBER = "View Member";
  static SUBMITTED = "Submitted";
  static FEEDBACK_HISTORY = "Feedback History";

  // Case Study Fields
  static PROJECT_CASESTUDY_ALREADY_EXISTS = "Project Casestudy Already Exists";
  static CASESTUDY_PROJECTS = "Projects";
  static CASE_STUDY_FUNCTIONAL_MANAGER = "Functional Manager";
  static ADD_TO_GROUP = "Add To Group";
  static CREATE_GROUP = "Create Group";
  static UPDATE_GROUP = "Update Group";
  static EDIT_GROUP = "Edit Group";
  static NEW_GROUP = "New Group";
  static GROUP_NAME = "Group Name";
  static ADD_ALL_MEMBERS = "Add all members";
  static SELECT_ALL_MEMBERS = "Select all members";
  static SELECT_YOUR_REPORTEES = "Select your reportees";
  static PRIMARY_ROLE = "Primary Role";
  static UPDATED_BY = "Updated By";
  static MT = "MT";
  static VERSIONS_HISTORY = "Versions History";
  static HISTORY_VERSION = "History Version";
  static PM_COMMENT = "PM Comment";
  static MARKETING_TEAM_COMMENT = "Marketing Team Comment";
  static PMO_COMMENT = "PMO Comment";
  static SUBMISSION_BY_PM = "Submission By PM";
  static SUBMISSION_BY_MARKETING_TEAM = "Submission By Marketing Team";
  static UPLOADED_BY_PM = "Uploaded By PM";
  static UPLOADED_BY_MT = "Uploaded By MT";
  static CASE_STUDY_SUBMISSION_DATE_PM = "Submission By PM";
  static CASE_STUDY_SUBMISSION_DATE_MT = "Submission By MT";
  static ACCEPT = "Accept";
  static REJECT = "Reject";
  static PENDING = "Pending";
  static PMO_APPROVED = "PMO Approved";
  static PMO_REJECTED = "PMO Rejected";
  static SUBMITTED_BY_MT = "Submitted by MT";
  static SUBMITTED_BY_PM = "Submitted by PM";
  static PUBLISHED = "Published";
  static PUBLISH_LINK = "Publish Link";
  static PUBLISH_CASE_STUDY = "Publish Case-Study";
  static OPEN_PUBLISHED_LINK = "Open Published Link";
  static KRA = "KRA";
  static PMO_ACTION = "PMO Action";
  static KRA_ROLES = "KRA Roles";
  static KRA_ROLE = "KRA ROLE";
  static ADD_KRA_ROLE = "Add KRA Role";
  static CASE_STUDY_ACCEPT_STATEMENT = "Are you sure you want to accept this case study?";

  // TOOLS FIELDS
  static IS_TOOLS = "IS-Tools";
  static TOOLS_LICENCE = "Tools Licence";
  static TOOLS_DASHBOARD = "Tools Dashboard";
  static USERNAME = "Username";
  static AUTO_RENEWAL = "Auto Renewal";
  static REMARKS = "Remarks";
  static RENEWAL_DATE = "Renewal Date";
  static LICENSE = "License";
  static LICENCE_PERIOD = "License Period";
  static ADD_TOOLS = "Add Tools";
  static ADD_LICENCE = "Add Licence";
  static ADD_NEW_TOOL = "Add New Tool";
  static ADD_NEW_PROFIT_CENTER = "Add New Profit Center";
  static ADD_NEW_COST_CENTER = "Add New Cost Center";
  static ADD_NEW_TOOL_TYPE = "Add New Tool Type";
  static EDIT_TOOLS_LICENCE = "Edit Tools Licence";
  static CREATE_NEW_TOOLS_LICENCE = "Create New Tool Licence";
  static ADD_NEW_DEPARTMENT = "Add New Department";
  static TOOLS_NAME = "Tools Name";
  static COST_PRICE = "Cost Price";
  static PROFIT_CENTER = "Profit Center";
  static COST_CENTER = "Cost Center";
  static TOOL_TYPE = "Tool Type";
  static TENURE = "Tenure";
  static MONTHLY = "Monthly";
  static QUARTERLY = "Quarterly";
  static BI_ANNUALLY = "Bi Annually";
  static YEARLY = "Yearly";
  static ONE_TIME = "One Time";
  static PERCENTAGE = "Percentage";
  static PURCHASE_DATE = "Purchase Date";
  static RENEW = "Renew";
  static EMAIL_CONFIGURATOR = "Email Configurator";
  static CONFIGURATION_NAME = "Configuration Name";
  static CONFIGURATION_VALUE = "Configuration Value";
  static CONFIGURATION_TYPE = "Configuration Type";
  static EMAIL_IN_CC = "Email In CC";
  static EMAIL_IN_TO = "Email In To";
  static ADD_EMAIL = "Add Email";
  static FREQUENCY = "Frequency";
  static REMAINDER_FREQUENCY_1 = "Remainder Frequency 1";
  static REMAINDER_FREQUENCY_2 = "Remainder Frequency 2";
  static ON_EMAIL = "On Email";
  static OFF_EMAIL = "Off Email";
  static LICENSE_HOLDER_NAME = "License Holder Name";
  static LICENSE_HOLDER_EMAIL = "License Holder Email";
  static DUPLICATE_EMAIL = "Don't Enter Duplicate Email";
  static FREQUENCY_CAN_NOT_BE_ZERO = "Frequency Can't Be 0";
  static EMAIL_CONFIGURATOR_UPDATED_SUCCESSFULLY = "Email Configurator Updated Successfully";
  static TOOL_ADDED_SUCCESSFULLY = "Tool Added Successfully";
  static TOOL_UPDATED_SUCCESSFULLY = "Tool Updated Successfully";
  static TOOL_DELETED_SUCCESSFULLY = "Tool Deleted Successfully";
  static PROFIT_CENTER_ADDED_SUCCESSFULLY = "Profit Center Added Successfully";
  static PROFIT_CENTER_UPDATED_SUCCESSFULLY = "Profit Center Updated Successfully";
  static PROFIT_CENTER_DELETED_SUCCESSFULLY = "Profit Center Deleted Successfully";
  static COST_CENTER_ADDED_SUCCESSFULLY = "Cost Center Added Successfully";
  static COST_CENTER_UPDATED_SUCCESSFULLY = "Cost Center Updated Successfully";
  static COST_CENTER_DELETED_SUCCESSFULLY = "Cost Center Deleted Successfully";
  static TOOL_TYPE_ADDED_SUCCESSFULLY = "Tool Type Added Successfully";
  static TOOL_TYPE_UPDATED_SUCCESSFULLY = "Tool Type Updated Successfully";
  static TOOL_TYPE_DELETED_SUCCESSFULLY = "Tool Type Deleted Successfully";
  static MONTHLY_COST = "Monthly Cost";
  static QUARTERLY_COST = "Quarterly Cost";
  static BI_ANNUALLY_COST = "Bi Annually Cost";
  static YEARLY_COST = "Yearly Cost";
  static TOTAL = "Total";
  static COST_PRICE = "Cost Price";
  static LICENSE_HOLDER = "License Holder";
  static LICENSE_DETAIL = "License Detail";
  static RENEWAL_DATE_FROM = "Renewal Date From";
  static RENEWAL_DATE_TO = "Renewal Date To";
  static PURCHASE_START_DATE = "Purchase Start Date";
  static PURCHASE_END_DATE = "Purchase End Date";
  static UPLOAD_LICENSE_XL = "Upload License Excel";
  static UPLOAD_CONTARCT = "Upload Contract";
  static CONTRACT_HISTORY = "Contract History";
  static ARE_YOU_SURE = "Are You Sure ?";
  static CONTRACT_DELETED_SUCESSFULLY = "Contract Deleted Sucessfully";
  static CONTRACT_UPLOAD_SUCESSFULLY = "Contract Uploaded Sucessfully";
  static LICENSE_RENEWED_SUCCESSFULLY = "License Renewed Successfully";
  static LICENSE_ASSIGNED_SUCCESSFULLY = "License Assigned Successfully";
  static LICENSE_UPLOAD_SUCCESSFULLY = "License Uploaded Successfully";
  static LICENSE_UPDATED_SUCCESSFULLY = "License Updated Successfully";
  static LICENSE_ACTIVATED_SUCCESSFULLY = "License Activated Successfully";
  static LICENSE_DEACTIVATED_SUCCESSFULLY = "License Deactivated Successfully";
  static TOGGLE_TO_SHOW_ACTIVE_LICENSE = "Toggle to Show Active License";
  static TOGGLE_TO_SHOW_INACTIVE_LICENSE = "Toggle to Show Inactive License";
  static WANT_TO_DEACTIVATE_LICENSE = "Want to Deactivate License";
  static WANT_TO_ACTIVATE_LICENSE = "Want to Activate License";
  static WANT_TO_RENEW_LICENSE = "Want to Renew License";
  static ADD_EDIT_TOOLS = "Add/Edit Tools";
  static ADD_EDIT_DEPARTMENT = "Add/Edit Department";
  static ADD_EDIT_COST_CENTER = "Add/Edit Cost Center";
  static ADD_EDIT_TOOL_TYPE = "Add/Edit Tool Type";
  static REFERRALS = "Referrals";
  static TA = "TA";
  static RECENT_JOINERS = "Recent Joiners";
  static PREVIOUS_COMPANY = "Previous Company";
  static EMPLOYEE_LIST = "Employees with Previous Experience at ";
  static RECOMMENDED_BY = "Recommended By";
  static SEND_REFERRAL_REQUEST = "Send Referral Request";
  static LAST_REQUEST = "Last Request";

  // REFERRAL
  static REFERRAL = "Referral";
  static REFER = "Refer";
  static EMPLOYEE_REFERRAL_FORM = "Employee Referral Form";
  static EMPLOYEE_RECOMMENDATION_FORM = "Recommendation Form";
  static ADD_RECOMMENDATION = "Add Recommendation";
  static REFERRAL_NAME = "Referral Name";
  static PHONE_NUMBER = "Phone Number";
  static REFERRAL_EMAIL = "Referral Email";
  static REFERRAL_DATE = "Referral Date";
  static REFERRED_BY = "Referred By";
  static COMPANY = "Company";
  static SKILLS = "Skills";
  static OTHER = "Other";
  static CREATE_NEW_REFERRAL = "Create New Referral";
  static RECOMMENDATION_ADDED = "Recommendation Added Successfully";
  static CONTACT_DETAIL_ERROR_MESSAGE = "Please provide at least one valid contact detail (phone number, email, or LinkedIn URL)";
  static SUITABLE_DEPARTMENT = "Suitable Department";
  static RECOMMENDATION_DETAILS = "Candidate Recommendation Details";
  static REFERRAL_COUNT = "Recommendation Count";
  static NO_OF_EMPLOYEES = "No. of employees";
  static COMPANY_DETAILS = "Company Details";
  static LOCATION = "Location";
  static COMPANY_DETAILS_ADDED_SUCCESSFULLY = "Company Details Added Successfully";
  static COMPANY_DETAILS_UPDATED_SUCCESSFULLY = "Company Details Updated Successfully";
  static MIN_MAX_ERROR = "Min must be less than Max.";
  static COMPANY_ALREADY_EXISTS = "Company Name already Exists";
  static FILE_1 = "File 1";
  static FILE_2 = "File 2";
  static RESUME = "Resume";
  static INVALID_EMAIL = "Please enter a valid email address.";
  static INVALID_PHONE_NUMBER = "Please enter a valid phone number.";
  static INVALID_LINKEDIN_URL = "Please enter a valid LinkedIn URL.";

  // Vender Management
  static PAN = "PAN";
  static GST = "GST";
  static VENDOR = "Vendor";
  static OWNER = "Owner";
  static RESOURCE = "Resource";
  static PAYMENT = "Payment";
  static NET_DUE = "Net Due";
  static NOTICE_PERIOD = "Notice Period";
  static CLIENT_HOUR_ALLOC = "Client Hours";
  static ACTUAL_HOUR = "Actual Hours";
  static COMMITMENT = "Commitment";
  static OVERALL_RATING = "Overall Rating";
  static TIME_ALLOCATION = "Time Allocation";
  static USD = "USD";
  static INR = "INR";
  static CAD = "CAD";
  static VENDOR_CURRENCY = "Vendor Currency";
  static RESOURCE_ADDED_SUCCESSFULLY = "Resource Added Successfully";
  static CSM = "CSM";
  static BEHAVIOUR = "Behaviour";
  static VENDOR_COST = "Vendor Cost";
  static RESOURCE_DELETED_SUCCESSFULLY = "Resource Deleted Successfully";
  static RESOUCE_UPDATE_SUCCESSFULLY = "Resource Updated Succesfully";
  static POC = "POC";
  static UPWORK = "Upwork";
  static DIRECT = "Direct";
  static RONE = "One or above";
  static RTWO = "Two or above";
  static RTHREE = "Three or above";
  static RFOUR = "Four or above";
  static RFIVE = "Five";
  static ADD_VENDOR = "Add Vendor";
  static ADD_RESOURCE = "Add Resource";
  static VMS = "VMS";
  static VENDOR_ADD_SUCCESS = "Vendor Added Successfully";
  static VENDOR_UPDATE_SUCCESS = "Vendor Updated Successfully";
  static NEXT_DUE = 'Next due'

  //
  static EMPLOYEE_PROFILE = "Profile";
  static NOT_AVAILABLE = "Not Available";
  static TITLE = "Title";
  static LEVEL = "Level";
  static DUPLICATE_COMPANY_NAME = "Duplicate company name";

  //TECHNICAL_INTERVIEW

  static TECHNICAL_INTERVIEW = "Technical Interview";
  static ASSIGN_TECHNICAL_INTERVIEW = "Assign Technical Interview";
  static TECHNICAL_INTERVIEW_STARTED_SUCCESSFULLY = "Technical Interview Started Successfully";
  static TECHNICAL_INTERVIEW_SUBMITTED_SUCCESSFULLY = "Technical Interview Submitted Successfully";
  static RESULT = "Result";

  //L and D dialog
  static MENTOR = "Mentor";
  static TRAINING_TYPE = "Training Type";
  static TASKBOX = "Taskbox";
  static ACT = "Act";

  // Business Central Fields
  static BUSINESS_CENTRAL = "Business Central";
  static BYT_PROJECT = "BYT Project";
  static FIXED_COST_PROJECT = "Fixed Cost Project";
  static T_AND_M = "T&M";
  static CSD = "CSD";
  static FIXED_PROJECTS = "Fixed Projects";
  static TIME_AND_MATERIAL = "Time & Material";
  static CSD_PROJECT = "CSD Project";
  static CSM_DETAILS = "CSM Details";
  static RESOURSE_DETAILS = "Resource Details";
  static BYT_STATUS = "BYT Status";
  static PROJECT_NO = "Project No";
  static PROJECT_STATUS = "Project Status";
  static VICE_PRESIDENT = "Vice President";
  static INDUSTRY = "Industry";
  static COMPANY_REGION = "Company Region";
  static COMPANY_ADDRESS = "Company Address";
  static CLIENT_INDUSTRY = "Client Industry";
  static CLIENT_EMAIL_ID = "Client Email Id";
  static SPOC_NAME = "SPOC Name";
  static CLIENT_COMPANY_LINK = "Client Company Link";
  static PROJECT_TYPE = "Project Type";
  static PROJECT_NUMBER = "Project Number";
  static POC_NAME = "POC Name";
  static POC_EMAIL = "POC Email";
  static POC_NUMBER = "POC Number";
  static PROJECT_START_DATE = "Project Start Date";
  static PROJECT_END_DATE = "Project End Date";
  static PROJECT_DELETED_SUCCESSFULLY = "Project Deleted Successfully";
  static PROJECT_ESTIMATE = "Project Estimate";
  static ORIGINAL_TIME_ESITIMATE_IN_HRS = "Original Time Estimate (in hours)";
  static TOTAL_COST_IN_RUPEES = "Total Cost (in rupees)";
  static CURRENCY = "Currency";
  static CLIENT = "Client";
  static BIRTHDAY = "Birthday";
  static COUNTRY = "Country";
  static CLIENT_DESIGNATION = "Client Designation";
  static OCCASION = "Occasion";
  static FESTIVAL = "Festival";
  static CUSTOM_OCCASION = "Custom Occasion";
  static GIFT = "Gift";
  static GIFT_TYPE = "Gift Type";
  static BIG = "Big";
  static MEDIUM = "Medium";
  static SMALL = "Small";
  static CARD = "Card";
  static NONE = "None";
  static GIFT_STATUS = "Gift Status";
  static PROPOSED_GIFT = "Proposed Gift";
  static CLIENT_GIFT = "Client Gift";
  static GIFT_ELIGIBITY = "Gift Eligibity";
  static SPOC_DETAILS = "SPOC Details";
  static CLIENT_DETAILS = "Client Details";
  static SPOC = "SPOC";
  static CLIENT_EMPLOYEE_SIZE = "Client Employee Size";
  static ADDRESS = "Address";
  static SAME_AS_COMPANY = "Same As Company";
  static CLIENT_CONTACT_NUMBER = "Client Contact Number";
  static CLIENT_COMPANY_NAME = "Client Company Name";
  static SALES_REPRESENTATIVE = "Sales Representative";
  static APPROVED = "Approved";
  static ADD_EDIT_SALES_REPRESNTATIVE = "Add/Edit Sales Representative";
  static SALES_REPRESENTATIVE_SAVED_SUCCESSFULLY = "Sales Representative Saved Successfully";
  static SALES_REPRESENTATIVE_UPDATED_SUCCESSFULLY = "Sales Representative Updated Successfully";
  static SALES_REPRESENTATIVE_DELETED_SUCCESSFULLY = "Sales Representative Deleted Successfully";
  static GIFT_SAVED_SUCCESSFULLY = "Gift Saved Successfully";
  static GIFT_SUBMIT_SUCCESSFULLY = "Gift Submit Successfully";
  static GIFT_APPROVED_SUCCESSFULLY = "Gift Approved Successfully";
  static CONFIRM_DELETE = "Are you sure? you want to delete this row?";
  static TOTAL_COST = "Total Cost";
  static REVENUE_GENERATED = "Revenue Generated";
  static ACCOUNT_INVOICE = "Account Invoice";
  static RESOURCE_NAME = "Resource Name";
  static NOTICE_PERIOD = "Notice Period";
  static RESOURCE_TYPE = "Resource Type";
  static MONTHLY_SPEND_HOURS = "Monthly Spend (Hrs)";
  static ACTUAL_SPEND_HOURS = "Actual Spend (Hrs)";
  static COST_CLIENT = "Cost Client";
  static INVOICE_DATE = "Invoice Date";
  static HOLD = "Hold";
  static COO = "COO";
  static PARTIAL = "Partial";
  static SUPPORT = "Support";
  static SHADOW = "Shadow";
  static BILLABLE = "Billable";
  static INR = "INR";
  static USD = "USD";
  static CAD = "CAD";
  static AUD = "AUD";

  //TECHNICAL_INTERVIEW

  static TECHNICAL_INTERVIEW = "Technical Interview";
  static ASSIGN_TECHNICAL_INTERVIEW = "Assign Technical Interview";
  static TECHNICAL_INTERVIEW_STARTED_SUCCESSFULLY = "Technical Interview Started Successfully";
  static TECHNICAL_INTERVIEW_SUBMITTED_SUCCESSFULLY = "Technical Interview Submitted Successfully";
  static RESULT = "Result";
  // Business Central Fields
  static BUSINESS_CENTRAL = "Business Central";
  static BYT_PROJECT = "BYT Project";
  static FIXED_COST_PROJECT = "Fixed Cost Project";
  static T_AND_M = "T&M";
  static CSD = "CSD";
  static FIXED_PROJECTS = "Fixed Projects";
  static TIME_AND_MATERIAL = "Time & Material";
  static CSD_PROJECT = "CSD Project";
  static CSM_DETAILS = "CSM Details";
  static RESOURSE_DETAILS = "Resource Details";
  static PROJECT_NO = "Project No";
  static PROJECT_STATUS = "Project Status";
  static VICE_PRESIDENT = "Vice President";
  static CLIENT_INDUSTRY = "Client Industry";
  static CLIENT_EMAIL_ID = "Client Email Id";
  static CLIENT_COMPANY_LINK = "Client Company Link";
  static PROJECT_TYPE = "Project Type";
  static PROJECT_NUMBER = "Project Number";
  static PROJECT_POC_NAME = "Project POC Name";
  static PROJECT_POC_EMAIL = "Project POC Email";
  static PROJECT_POC_NUMBER = "Project POC Number";
  static PROJECT_START_DATE = "Project Start Date";
  static PROJECT_END_DATE = "Project End Date";
  static PROJECT_DELETED_SUCCESSFULLY = "Project Deleted Successfully";
  static PROJECT_ESTIMATE = "Project Estimate";
  static ORIGINAL_TIME_ESITIMATE_IN_HRS = "Original Time Estimate (in hours)";
  static TOTAL_COST_IN_RUPEES = "Total Cost (in rupees)";
  static CURRENCY = "Currency";
  static CLIENT = "Client";
  static CLIENT_BIRTHDAY = "Client Birthday";
  static CLIENT_DESIGNATION = "Client Designation";
  static CLIENT_GIFT = "Client Gift";
  static GIFT_ELIGIBITY = "Gift Eligibity";
  static CLIENT_DETAILS = "Client Details";
  static CLIENT_EMPLOYEE_SIZE = "Client Employee Size";
  static CLIENT_ADDRESS = "Client Address";
  static CLIENT_CONTACT_NUMBER = "Client Contact Number";
  static CLIENT_COMPANY_NAME = "Client Company Name";
  static SALES_REPRESENTATIVE = "Sales Representative";
  static ADD_EDIT_SALES_REPRESNTATIVE = "Add/Edit Sales Representative";
  static SALES_REPRESENTATIVE_SAVED_SUCCESSFULLY = "Sales Representative Saved Successfully";
  static SALES_REPRESENTATIVE_UPDATED_SUCCESSFULLY = "Sales Representative Updated Successfully";
  static SALES_REPRESENTATIVE_DELETED_SUCCESSFULLY = "Sales Representative Deleted Successfully";
  static CONFIRM_DELETE = "Are you sure? you want to delete this row?";
  static TOTAL_COST = "Total Cost";
  static REVENUE_GENERATED = "Revenue Generated";
  static ACCOUNT_INVOICE = "Account Invoice";
  static RESOURCE_NAME = "Resource Name";
  static NOTICE_PERIOD = "Notice Period";
  static RESOURCE_TYPE = "Resource Type";
  static MONTHLY_SPEND_HOURS = "Monthly Spend (Hrs)";
  static ACTUAL_SPEND_HOURS = "Actual Spend (Hrs)";
  static COST_CLIENT = "Cost Client";
  static INVOICE_DATE = "Invoice Date";
  static HOLD = "Hold";
  static INR = "INR";
  static USD = "USD";
  static CAD = "CAD";
  static AUD = "AUD";

  // Hardware
  static HARDWARE = "Hardware";
  static HARDWARE_DETAIL = "Hardware Detail";
  static HARDWARE_HISTORY = "Hardware History";
  static HARDWARE_DASHBOARD = "Hardware Dashboard";
  static ASSIGN_HARDWARE = "Assign Hardware";
  static ADD_HARDWARE = "Add Hardware";
  static EDIT_HARDWARE = "Edit Hardware";
  static ASSIGN_REVOKE_HARDWARE = "Assign/Revoke Hardware";
  static CREATE_NEW_HARDWARE = "Create New Hardware";
  static ADD_HARDWARE_TYPE = "Add Hardware Type";
  static ADD_MANUFACTURER = "Add Manufacturer";
  static HARDWARE_ITEM = "Hardware Item";
  static SERIAL_NUMBER = "Serial Number";
  static HARDWARE_TYPE = "Hardware Type";
  static MANUFACTURER = "Manufacturer";
  static MODEL = "Model";
  static RAM = "RAM";
  static WINDOWS_OS = "Windows OS";
  static HDD = "HDD";
  static PURCHASE_ORDER_NO = "Purchse Order No";
  static HOST_NAME = "Host Name";
  static SEAT_NUMBER = "Seat Number";
  static SYSTEM_DETAILS = "System Details";
  static RETURN_DATE = "Return Date";
  static RETURN_START_DATE = "Return Start Date";
  static RETURN_END_DATE = "Return End Date";
  static ASSIGNED_DATE = "Assigned Date";
  static ASSIGN_START_DATE = "Assign Start Date";
  static ASSIGN_END_DATE = "Assign End Date";
  static HISTORY_START_DATE = "History Start Date";
  static HISTORY_END_DATE = "History End Date";
  static WARRANTY_END_DATE = "Warranty End Date";
  static WARRANTY_START_DATE = "Warranty Start Date";
  static ASSIGNED_HARDWARE = "Assigned Hardware";
  static BULK_HARDWARE_UPLOAD = "Bulk Hardware Upload";
  static ADD_EDIT_HARDWARE_TYPE = "Add/Edit Hardware Type";
  static ADD_EDIT_MANUFACTURER = "Add/Edit Manufacturer";
  static TOGGLE_TO_SHOW_ASSIGNED_HARDWARE = "Toggle to show Assigned Hardware Items";
  static TOGGLE_TO_SHOW_UNASSIGNED_HARDWARE = "Toggle to show Unassigned Hardware Items";
  static HARDWARE_ASSIGNED_SUCCESSFULLY = "Hardware Assigned Successfully";
  static HARDWARE_UPDATED_SUCCESSFULLY = "Hardware Updated Successfully";
  static HARDWARE_ADDED_SUCCESSFULLY = "Hardware Added Successfully";
  static HARDWARE_TYPE_ADDED_SUCCESSFULLY = "Hardware Added Successfully";
  static HARDWARE_TYPE_UPDATED_SUCCESSFULLY = "Hardware Updated Successfully";
  static HARDWARE_TYPE_DELETED_SUCCESSFULLY = "Hardware Deleted Successfully";
  static MANUFACTURER_ADDED_SUCCESSFULLY = "Manufacturer Added Successfully";
  static MANUFACTURER_UPDATED_SUCCESSFULLY = "Manufacturer Updated Successfully";
  static MANUFACTURER_DELETED_SUCCESSFULLY = "Manufacturer Deleted Successfully";

  //L and D dialog
  static MENTOR = "Mentor";

  //Campus Placement
  static CAMPUS_PLACEMENT = "Campus Placement";
  static CAMPUS_PLACEMENT_ACTIONS = "Actions";
  static CAMPUS_PLACEMENT_LIST_VIEW = "Campus Placement Evaluation";
  static CANDIDATE_NAME = "Name";
  static CANDIDATE_EMAIL = "Email";
  static CANDIDATE_COLLEGE_NAME = "College Name";
  static CANDIDATE_STREAM = "Stream";
  static SCREENSHOT = "Screenshots";
  static COMMUNICATION_SCORE = "Communication Score";
  static QUESTION_ENGAGEMENT_SCORE = "Question Engagement Score";
  static LOGICAL_LEVEL_SCORE = "Logical Level Score";
  static MOCK_AVERAGE = "Mock Average";
  static NUMBER_OF_QUESTIONS_ATTEMPTED = "Questions Attempted";

  //L and D dialog
  static MENTOR = "Mentor";
  static TRAINING_TYPE = "Training Type";
  static TASKBOX = "Taskbox";
  static ACT = "Act";

  static COMMUNICATION_ASSESSMENT = "Communication Assessment";
  static COMMUNICATION_ASSESSMENT_LIST_VIEW = "Communication Assessment Evaluation";
  static MOCK_VIDEO_INTERVIEW = "Mock Video Interview"

  //dashboard
  static AVAILABILITY_SINCE = "Availability Since";
  static SUPERVISION = "Supervision";
  static LEADERSHIP = "Leadership";
  static SALES_MARKETING = "Sales-Marketing";
}
